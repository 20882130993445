import React, {useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

// const getOptionLabel = option => option;

// const optionRenderer = (option, { selected }) => {
//   // const selectAllProps =
//   //   items.length > 0 && option === "выбрать ВСЕ" // To control the state of 'select-all' checkbox
//   //     ? { checked: allSelected }
//   //     : {};
//   return (
//     <>
//       <Checkbox
//         color="secondary"
//         icon={<CheckBoxOutlineBlankIcon fontSize="small" />} 
//         checkedIcon={<CheckBoxIcon fontSize="small" />}
//         style={{ marginRight: 8 }}
//         checked={selectedOption}
//         //{...selectAllProps}
//       />
//       {getOptionLabel(option)}
//     </>
//   );
// };
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />

export default function SingleSelectPlain(props) {
  const classes = useStyles();

  const {label, style, items, value, setValue, fn, array} = props;

  return (
    <div className={classes.root}>
      <Autocomplete
        style={style}
        id="tags-standard"
        options={items}
        value={value}
        //getOptionLabel={(option) => option}
        //getOptionSelected={(option, value) => option === value} 
        onChange={(event, newValue) => {
            setValue(newValue);
            console.log('new Value: ', newValue);
            if(fn) {
              if(array) {
                fn([].concat(newValue)) 
              }
              else {
                fn(newValue)
              }               
            }
        }}
        
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"            
            color="secondary"            
            label={label}
          />
        )}
      />
     
    </div>
  ); 
}

