import React from 'react';
import { useSelector } from 'react-redux';  

import { makeStyles } from '@material-ui/core/styles';
import MonitoringsAutoTable from '../monitoringsTables/monitoringsAutoTable';
import MonitoringsLeftTable from '../monitoringsTables/monitoringsLeftTable';
import MonitoringsRightTable from '../monitoringsTables/monitoringsRightTable';
import MonitoringsRightUnbindTable from '../monitoringsTables/monitoringsRightUnbindTable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  twoTables: {
    maxHeight: 900,
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row nowrap',
      
  },
  divider: {
    width: 14,
  },
}));

function makeRecord(item, index){
  return {
    id: index,
    photo: item,
  }
}

export default function MonitoringsContainer() {
  const classes = useStyles();
  
  const { mode, autoFlag, selectedIndex, sideFlag, typeFlag, geoIndex } = useSelector(state => state.shared);
  const { autoTableData, filteredMprDetailsSimple, filteredMprDetails, monitoringsPhotos } = useSelector(state => state.mprDetails);
  
  let content;
  if(mode === 'F2'){
    if(selectedIndex === 2) {
      content = (
        <div className={classes.twoTables}>    
          <MonitoringsLeftTable
            rows={filteredMprDetailsSimple} 
            selectedIndex={selectedIndex}
          />  

          <div className={classes.divider}></div> 

          {monitoringsPhotos.length>0 &&
            <MonitoringsRightUnbindTable   
              rows={monitoringsPhotos}               
            />}

        </div>
      )
    } else {
      content = (
        <div className={classes.twoTables}>    
          <MonitoringsLeftTable
            rows={filteredMprDetailsSimple} 
            selectedIndex={selectedIndex}
          />  

          <div className={classes.divider}></div>

          < MonitoringsRightTable
            rows={filteredMprDetails}
              autoFlag={autoFlag}
              sideFlag={sideFlag}
              typeFlag={typeFlag}
              geoIndex={geoIndex}
              selectedIndex={selectedIndex}
          />
        </div>
      )
    }
  } else {
    // mode === 'F1'
      content = (
        <div className={classes.root}>
          <MonitoringsAutoTable 
            rows={autoTableData}
            selectedIndex={selectedIndex} 
          />            
        </div>
      )
  }

  // console.log('mode / CONTAINER: ', mode);
  // console.log('monitoringsPhotos / CONTAINER: ', monitoringsPhotos);
  // console.log('autoTableData / CONTAINER: ', autoTableData);
  // console.log('filteredMprDetails / CONTAINER: ', filteredMprDetails);
  // console.log('filteredMprDetailsSimple / CONTAINER: ', filteredMprDetailsSimple);

 return content;
}
