import {
    RETRIEVE_MPR_DETAILS,
    GET_FILTERED_MPR_DETAILS,
    GET_FILTERED_MPR_DETAILS_SIMPLE,
    GET_ONE_MPR_DETAIL_BY_ID,
    // GET_MP_COMMUNICATION_FROM_MPR_DETAIL,
    UPDATE_ONE_MPR_DETAIL,
    UPDATE_MANY_FILTERED_MPR_DETAILS,
    SELECT_UNRELATED,
    GET_BRANDS_FROM_MPR_DETAILS,
    GET_BRANDS,
    GET_BRANDS_FOR_BRANDS,
    GET_BRANDS_RELATED,
    SELECT_BRANDS,
    BIND_PHOTO_TO_THE_CLIENTS_GO,
    BIND_BRANDS_TO_THE_CLIENTS_GO,
    GET_MONTHS_RELATED_TO_THE_CLIENT,
    SELECT_MPR_DETAILS,
    GET_MPR_DETAILS_FOR_MONITORINGS,
    GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE,
    IS_LOADING,
    SET_AUTO_TABLE_DATA,
    UPDATE_FILTERED_MPR_DETAILS_SIMPLE,
    SELECT_MONITORINGS_AUTO_TABLE_ADDRESSES,
    UPDATE_AUTO_TABLE_DATA,
    GET_FILTERED_CLIENTS_GO,
    SHOW_MONITORINGS_PHOTOS,
    SELECT_MONITORINGS_PHOTOS,
    SHOW_AUTO,
    SET_THE_MODE,
    SHOW_GEO,
  } from "./types";
  
  import mprDetailsService from "../../services/mprDetailsService";
  import {to7digitals, isInitialState, removeDuplicates} from '../../utils/shared';

  const customTrim = (str) => {
    if(!str) { str = 'Бренд не проставлен'; } else { str = str.trim(); } 
    return str;
  };

  function qtyArr(arr){    
    let list2 = [...new Set(arr.map(obj => customTrim(obj.mprd_brand)))];
    // console.log('list2: ', list2);

    let newArr = [];
    for (let i = 0; i < list2.length; i++){
        let id = i;
        let name = list2[i];
        let occurancy = arr.filter(item => customTrim(item.mprd_brand) === name).length;
        // console.log('brand: ', name, ' occurancy: ', occurancy);
         
        newArr.push({
            id: id,
            united: name,
            qty: occurancy
        });
    }
    return newArr.sort();
  }

  function _addPhotoLinks(filteredMprDetailsSimple){
    let newArr = [];
    filteredMprDetailsSimple.forEach(el => {
  
      let obj = newArr.find(item => item.mpd_id === el.mpd_id);
      //console.log('obj внутри: ', obj);
      if(obj) {
        const index = newArr.findIndex(item => item.mpd_id === el.mpd_id);
        // console.log('index: ', index);
        if(el.uf_extension === 'jpg' && el.mprdp_deleted !== 1) {
          newArr[index].photos.push("https://monitoring.graceoutdoor.ru/" + to7digitals(el.mprd_mpr_id) + "/" + to7digitals(el.mprdp_photo_assigned_uf_id) + "_" + to7digitals(el.uf_rand) + "_900." + el.uf_extension);
        } else {
          //newArr[index].photos.push("https://via.placeholder.com/900x675");
          newArr[index].passport = el.mpd_photo_url ? el.mpd_photo_url : '';
          newArr[index].mprdp_deleted = 0;
        }      
      } else {
        if(el.uf_extension === 'jpg' && el.mprdp_deleted !== 1) {
          newArr.push(Object.assign({}, el, {
                photos: [].concat("https://monitoring.graceoutdoor.ru/" + to7digitals(el.mprd_mpr_id) + "/" + to7digitals(el.mprdp_photo_assigned_uf_id) + "_" + to7digitals(el.uf_rand) + "_900." + el.uf_extension),
                passport: '',
              }
            ));
        } else {
          newArr.push(Object.assign({}, el, {
                photos: [],
                passport: el.mpd_photo_url ? el.mpd_photo_url : '',
              },
              {
                mprdp_deleted: 0
              }
            ));
        }      
      }
    })
    return newArr;
  }
  
  function makeAddressProgrammSimple(filteredMprDetailsSimple){
    const makeAddress = (item) => {
      return {
        id: item.mpd_id,
        maket: item.mpd_design,
        addressSimple: item.mpd_address,
        sideSimple: item.mpd_side,
        typeSimple: item.mpd_unit_type, 
        lat: item.lat,
        lng: item.lng,
        photos: item.photos,
        passport: item.passport,
        mpr_id_simple: item.mprd_mpr_id,
        mprd_id_simple: item.mprd_id,
        mprdp_id_simple: item.mprdp_id,
        mprdp_deleted_simple: item.mprdp_deleted,
        mprdp_photo_assigned_uf_id_simple: item.mprdp_photo_assigned_uf_id,
        uf_extension_simple: item.uf_extension,
        uf_rand_simple: item.uf_rand,
      }    
    }
  
    let arr = _addPhotoLinks(filteredMprDetailsSimple);
    console.log('arr: ', arr);
  
    return arr.map(item => makeAddress(item));
    //return filteredMprDetailsSimple.map(item => makeAddress(item));
  }

  function _transformClientGo(clientGo){
    return Object.assign({}, clientGo, {id: clientGo.cg_id, united: clientGo.cg_name + ' / ' + clientGo.ctg_name });    
  }
  
  const distance = (point1, point2) => Number(point1.lat) && Number(point2.lat) && Number(point1.lng) && Number(point2.lng)
	  ? Math.sqrt(Math.pow(Number(point1.lat) - Number(point2.lat), 2) + Math.pow(Number(point1.lng) - Number(point2.lng), 2))
    : console.log("Ошибка данных: point1: ", point1, ' point2: ', point2);

  const theSameSideAndType = (side1,side2,type1,type2) => side1.slice(0,1) === side2.slice(0,1) && type1 === type2 ? true : false;

  function addMonitoring(obj, arr){
    let minimum = 100000;
    let index = 0;

    for (let i = 0;i<arr.length;i++){
      if(distance(obj,arr[i]) < minimum && obj.mpd_id !== arr[i].mpd_id && theSameSideAndType(obj.sideSimple, arr[i].mpd_side, obj.typeSimple, arr[i].mpd_unit_type)) {
        minimum = distance(obj,arr[i]);
        index = i;
      }
    };
    
    return minimum === 100000 
      ? Object.assign({}, obj, { 
          mid: 1,
          address: 'НЕТ ВАРИАНТОВ',
        })
      : Object.assign({}, obj, {
        mid: arr[index].mprd_id,
        address: arr[index].mpd_address,
        side: arr[index].mpd_side,
        type: arr[index].mpd_unit_type,
        clientGo: arr[index].cg_name ? arr[index].cg_name : arr[index].mprd_brand ? arr[index].mprd_brand : '',
        wod_suppl_lat_simple: arr[index].wod_suppl_lat,
        wod_suppl_lng_simple: arr[index].wod_suppl_lng,
        unit_coord_lat: arr[index].unit_coord_lat,
        unit_coord_lng: arr[index].unit_coord_lng,
        mprdp_photo_assigned_uf_id: arr[index].mprdp_photo_assigned_uf_id,
        uf_rand: arr[index].uf_rand,
        uf_extension: arr[index].uf_extension,
        mprd_mpr_id: arr[index].mprd_mpr_id,
        mprd_id: arr[index].mprd_id,
        mprdp_id: arr[index].mprdp_id,
    })
  }

  export const selectUnrelated = (unrelated) => {
    return {
      type: SELECT_UNRELATED,
      payload: unrelated,
    }
  };

  export const retrieveMprDetails = (unrelated) => async (dispatch) => {
    try {
      const res = await mprDetailsService.getAll();
      const unrelatedMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data;

      // const indexOfMaxValue = brandsTableData.reduce(function(previousValue, currentValue, index, array) {        
      //   return currentValue.qty > array[previousValue].qty ? index : previousValue;
      // }, 0);
      // console.log('indexOfMaxValue: ', indexOfMaxValue);
  
      dispatch({
        type: RETRIEVE_MPR_DETAILS,
        payload: unrelatedMprDetails,
      });
    } catch (err) {
      console.log(err); 
    }
  };

  export const getFilteredMprDetails = (filter, unrelated) => async (dispatch) => {

    if(isInitialState(filter)) {
      console.log('Фильтр не задан, запрос отменен')
      return;
    }
    
    const res = await mprDetailsService.filter(filter);
    const filteredMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data;
  

    dispatch({
      type: GET_FILTERED_MPR_DETAILS,
      payload: filteredMprDetails,
    });
  };

  export const getFilteredMprDetailsSimple = (filter, unrelated) => async (dispatch) => {

    if(isInitialState(filter)) {
      console.log('Фильтр не задан, запрос отменен')
      return;
    }
    
    const res = await mprDetailsService.filter(filter);
    const filteredMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data;
  

    dispatch({
      type: GET_FILTERED_MPR_DETAILS_SIMPLE,
      payload: filteredMprDetails,
    });
  };

  export const getMprDetailsForMonitoringsSimple = (data) => async (dispatch) => {

    const res = await mprDetailsService.forMonitoringsSimple(data);
    const filteredMprDetailsSimple = res.data;  

    dispatch({
      type: GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE,
      payload: filteredMprDetailsSimple,
    });
  };

  export const getMprDetailsForMonitorings = (data) => async (dispatch) => {

    const res = await mprDetailsService.forMonitorings(data);
    const filteredMprDetails = res.data;  

    dispatch({
      type: GET_MPR_DETAILS_FOR_MONITORINGS,
      payload: filteredMprDetails,
    });
  };

  
  export const getMprDetailsForMonitoringsFilter1 = (data) => async (dispatch) => {

    dispatch({
      type: IS_LOADING,
      payload: 'Yes'
    });

    const res1 = await mprDetailsService.forMonitoringsSimple(data);
    const filteredMprDetailsSimple = res1.data;
    console.log('filteredMprDetailsSimple / mprDetails Action: ', filteredMprDetailsSimple);

    const addressProgramSimple = makeAddressProgrammSimple(filteredMprDetailsSimple);
    console.log('addressProgramSimple / mprDetails Action: ', addressProgramSimple);  

    dispatch({
      type: GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE,
      payload: addressProgramSimple,
    });

    const res2 = await mprDetailsService.forMonitorings(data);
    const filteredMprDetails = res2.data;
    console.log('filteredMprDetails / mprDetails Action: ', filteredMprDetails);  

    dispatch({
      type: GET_MPR_DETAILS_FOR_MONITORINGS,
      payload: filteredMprDetails,
    });

    let rows = []; 
    if(addressProgramSimple.length>0 && filteredMprDetails.length>0){
      for (let i = 0; i < addressProgramSimple.length; i++){
        rows.push(addMonitoring(addressProgramSimple[i], filteredMprDetails));
        //console.log('rows length: ', rows.length)
      }
    }
    else {
      alert('Ошибка данных при подготовке АВТО ТАБЛИЦЫ');
      dispatch({
        type: IS_LOADING,
        payload: 'No'
      })
      return;
    }

    dispatch({
      type: SET_AUTO_TABLE_DATA,
      payload: rows,
    })

    dispatch({
      type: SET_THE_MODE,
      payload: 'F1'
    })

    dispatch({
      type: IS_LOADING,
      payload: 'No'
    })

  };

  export const getMprDetailsForMonitoringsFilter2 = (data) => async (dispatch) => {

    dispatch({
      type: IS_LOADING,
      payload: 'Yes'
    });

    const res1 = await mprDetailsService.forMonitoringsSimple(data);
    const filteredMprDetailsSimple = res1.data;
    console.log('filteredMprDetailsSimple / mprDetails Action: ', filteredMprDetailsSimple);

    dispatch({
      type: RETRIEVE_MPR_DETAILS, 
      payload: filteredMprDetailsSimple,
    });

    const addressProgramSimple = makeAddressProgrammSimple(filteredMprDetailsSimple);
    console.log('addressProgramSimple / mprDetails Action: ', addressProgramSimple);  

    dispatch({
      type: GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE, 
      payload: addressProgramSimple,
    });

    const res2 = await mprDetailsService.forMonitorings(data);
    const filteredMprDetails = res2.data;
    console.log('filteredMprDetails / mprDetails Action: ', filteredMprDetails);  

    dispatch({
      type: GET_MPR_DETAILS_FOR_MONITORINGS,
      payload: filteredMprDetails,
    });

    const clientsGo = [...new Set(filteredMprDetails.map(item => item.cg_name))].sort();

    dispatch({
      type: GET_FILTERED_CLIENTS_GO,
      payload: clientsGo,
    });

    // let rows = []; 
    // if(addressProgramSimple.length>0 && filteredMprDetails.length>0){
    //   for (let i = 0; i < addressProgramSimple.length; i++){
    //     rows.push(addMonitoring(addressProgramSimple[i], filteredMprDetails));
    //     //console.log('rows length: ', rows.length)
    //   }
    // }
    // else {
    //   alert('Ошибка данных при подготовке АВТО ТАБЛИЦЫ');
    //   dispatch({
    //     type: IS_LOADING,
    //     payload: 'No'
    //   })
    //   return;
    // }

    // dispatch({
    //   type: SET_AUTO_TABLE_DATA,
    //   payload: rows,
    // })

    dispatch({
      type: SET_THE_MODE,
      payload: 'F2'
    })

    dispatch({
      type: SHOW_AUTO, 
      payload: 'No' 
    })

    dispatch({
      type: SHOW_GEO, 
      payload: 1
    })

    dispatch({
      type: IS_LOADING,
      payload: 'No'
    })

  };


  
  export const getBrandsForBrands = (filter, unrelated) => async (dispatch) => {
    try {
      const res = await mprDetailsService.brandsForBrands(filter, unrelated); 
      console.log('res data / ACTION: ', res.data);
      const brands = res.data.map((item, index) => ({
        id: index,
        united: item.mprd_brand,
        qty: item.qty
      }));

      dispatch({
        type: GET_BRANDS_FOR_BRANDS, 
        payload: brands,
      });
    }

    catch (err) {
      console.log(err);
    }
  };

  export const getBrands = (filter, unrelated) => async (dispatch) => {
    try {
      let brandsTableData;
      if( !filter || isInitialState(filter) ) {
        brandsTableData = [];
      }
      else {
      const res = await mprDetailsService.mprDetailsForBrands(filter);
      const filteredMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data; 

      dispatch({
        type: GET_FILTERED_MPR_DETAILS,
        payload: filteredMprDetails,
      });

      brandsTableData =  qtyArr(filteredMprDetails);
    }

      dispatch({
        type: GET_BRANDS,
        payload: brandsTableData,
      });
    } 
    catch (err) {
      console.log(err);
    }
  };

  
  export const updateMprDetail = (id, data) => async (dispatch) => {
    try {
      console.log('MprDetail NEW DATA: ', data);
      const res = await mprDetailsService.update(id, data);

      dispatch({
        type: UPDATE_ONE_MPR_DETAIL,
        payload: res.data,
      });


    } catch (err) {
        console.log(err);
    }
  };

  // сюда прилетают уже обновленные на сервере данные и просто частично обновляют FilteredMprDetails в Store
  export const updateManyFilteredMprDetails = (mprDetailsArray) => {
    return {
      type: UPDATE_MANY_FILTERED_MPR_DETAILS,
      payload: mprDetailsArray,
    }
  };

  export const getBrandsFromMprDetails = () => async (dispatch) => {
    try {
      const res = await mprDetailsService.brands();

      dispatch({
        type: GET_BRANDS_FROM_MPR_DETAILS,
        payload: res.data.map(item => item.mprd_brand),
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const getBrandsRelatedToTheClientGo = (filter, cg_id) => async (dispatch) => {
    try {
      const res = await mprDetailsService.filter(filter);
      const mprDetailsRelatedToTheClientGo = res.data.filter(item => item.mprd_client_go === cg_id);
      const brandsTableData =  qtyArr(mprDetailsRelatedToTheClientGo);      
  
      dispatch({
        type: GET_BRANDS_RELATED,
        payload: brandsTableData,
      });
    } 
    catch (err) {
      console.log(err);
    }
  }; 

  export const selectBrands = (brandsArray) => { 
    return {
      type: SELECT_BRANDS,
      payload: brandsArray,
    }
  };

  export const bindBrandsToTheClientsGo = async (data, token) => {
    try {
      console.log('data before: ', data);
      const res = await mprDetailsService.updateMany(data, token);      
        return await Promise.resolve(res.data);
    } catch (err) {
        return await Promise.reject(err);
    }
  };
  
  export const getMonthsRelatedToTheClientGo = (cg_id) => async (dispatch) => {
    try {
      console.log('вызов mprDetailsService.getAll внутри getMonthsRelatedToTheClient');
      const res = await mprDetailsService.getAll();
      const mprDetailsRelatedToTheClientGo = res.data.filter(item => item.mprd_client_go === cg_id);
      const months =  [...new Set(mprDetailsRelatedToTheClientGo.map(item => item.mpr_planned_date.substring(0,7)))];      
  
      dispatch({
        type: GET_MONTHS_RELATED_TO_THE_CLIENT,
        // payload: mprDetailsRelatedToTheClientGo,
        payload: months,
      });
    } 
    catch (err) {
      console.log(err);
    }
  };

  export const bindPhotosToTheClientsGo = (id, data) => async (dispatch) => { 
    try {
      console.log(`id: ${id}, data before / Photos: `, data);
      const res = await mprDetailsService.update(id, data);
  
      dispatch({
        type: BIND_PHOTO_TO_THE_CLIENTS_GO,
        payload: res.data,
      });
  
    } catch (err) {
        console.log(err);
    }
  };

  export const selectMprDetails = (arr) => {
    return {
      type: SELECT_MPR_DETAILS,
      payload: arr,
    }
  };

  export const updateFilteredMprDetailsSimple = (id, photos, mprdp_photo_assigned_uf_id, mprdp_deleted, ufIdToUnbind) => { 
    return { 
      type: UPDATE_FILTERED_MPR_DETAILS_SIMPLE,
      payload: {
        id,
        photos,
        mprdp_photo_assigned_uf_id,
        mprdp_deleted,
        ufIdToUnbind,
      },  
    }
  }; 

  export const updateAutoTableData = (id, photos, mprdp_photo_assigned_uf_id, mprdp_deleted) => { 
    return { 
      type: UPDATE_AUTO_TABLE_DATA,
      payload: {
        id,
        photos,
        mprdp_photo_assigned_uf_id, 
        mprdp_deleted,
      },  
    }
  };



  export const selectMonitoringsAutoTableAddresses = (arr) => { 
    return {
      type: SELECT_MONITORINGS_AUTO_TABLE_ADDRESSES,
      payload: arr,  
    }
  };
  
  export const showMonitoringsPhotos = (arr) => { 
    return {
      type: SHOW_MONITORINGS_PHOTOS,
      payload: arr,  
    }
  };
  
  export const selectMonitoringsPhotos = (arr) => { 
    return {
      type: SELECT_MONITORINGS_PHOTOS,
      payload: arr,  
    }
  };