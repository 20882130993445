import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mpr_details_photos`, { headers: authHeader() }); 
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mpr_details_photos/${id}`, { headers: authHeader() })
}

const getOneByUfId = (id) => {
    return axios.get(_baseApiUrl + `/mpr_details_photos/get_one_by_uf_id/${id}`, { headers: authHeader() })
}

const create = (data) => {
    return axios.post(_baseApiUrl + `/mpr_details_photos`, data, { headers: authHeader() });
}

const update = (id, data) => {
    return axios.patch(_baseApiUrl + `/mpr_details_photos/${id}`, data, { headers: authHeader() });
};


export default {
    getAll,
    get,
    getOneByUfId,
    create,
    update,   
};