import {
    RETRIEVE_MP_DETAILS,
    GET_FILTERED_MP_DETAILS,
    GET_FILTERED_MP_DETAILS_SIMPLE,
    GET_FILTERED_MP_DETAILS_WITHOUT_BRAND,
    UPDATE_FILTERED_MP_DETAIL,
    REMOVE_BINDED_MP_DETAIL_FROM_FILTERED_MP_DETAILS_WITHOUT_BRAND,
    GET_ALL_CITIES_FROM_MP_DETAILS,
    GET_FILTERED_CITIES_FROM_MP_DETAILS,
    GET_JOINT_CITIES_FROM_MP_DETAILS,
    GET_FILTERED_CLIENTS_GO_FROM_MP_DETAILS,
    GET_FILTERED_CLIENT_TYPES_GO_FROM_MP_DETAILS,
    GET_FILTERED_UNIT_TYPES_FROM_MP_DETAILS, 
    GET_MP_DETAILS_FOR_REPORT,

  } from "./types";
  
import mpDetailsService from "../../services/mpDetailsService";
import {isInitialState, removeDuplicates, to7digitals} from '../../utils/shared';

const customTrim = (str) => {
  if(!str) { str = 'Бренд не проставлен'; } else { str = str.trim(); } 
  return str;
};

function _transformCities(city){
  return Object.assign({}, {id: city.mpd_city_id, united: city.mpd_city });    
}

function _transformClientGo(clientGo){
  return Object.assign({}, clientGo, {id: clientGo.cg_id, united: clientGo.cg_name + ' / ' + clientGo.ctg_name });    
}

function _transformClientTypeGo(clientTypeGo){
  return Object.assign({}, {id: clientTypeGo.ctg_id, united: clientTypeGo.ctg_name });     
}

function _addPhotoLinks(filteredMpDetailsSimple){
  let newArr = [];
  filteredMpDetailsSimple.forEach(el => {

    let obj = newArr.find(item => item.mpd_id === el.mpd_id);
    console.log('obj внутри: ', obj);
    if(obj) {
      const index = newArr.findIndex(item => item.mpd_id === el.mpd_id);
      // console.log('index: ', index);
      if(el.uf_extension === 'jpg') {
        newArr[index].photos.push("https://monitoring.graceoutdoor.ru/" + to7digitals(el.mpr_id) + "/" + to7digitals(el.mprdp_photo_assigned_uf_id) + "_" + to7digitals(el.uf_rand) + "_900." + el.uf_extension);
      } else {
        //newArr[index].photos.push("https://via.placeholder.com/900x675");
        newArr[index].passport = el.mpd_photo_url ? el.mpd_photo_url : '';
      }      
    }
    else {
      if(el.uf_extension === 'jpg') {
        newArr.push(Object.assign({}, el, {
              photos: [].concat("https://monitoring.graceoutdoor.ru/" + to7digitals(el.mpr_id) + "/" + to7digitals(el.mprdp_photo_assigned_uf_id) + "_" + to7digitals(el.uf_rand) + "_900." + el.uf_extension),
              passport: '',
            }
          ));
      } else {
        newArr.push(Object.assign({}, el, {
              photos: [],
              passport: el.mpd_photo_url ? el.mpd_photo_url : '',
          }));
      }      
    }
  })
  return newArr;
}

function makeAddressProgrammSimple(filteredMpDetailsSimple){
  const makeAddress = (item) => {
    return {
      id: item.mpd_id,
      maket: item.mpd_design,
      addressSimple: item.mpd_address,
      sideSimple: item.mpd_side,
      typeSimple: item.mpd_unit_type, 
      lat: item.lat,
      lng: item.lng,
      // lat: +item.wod_suppl_lat>0 ? item.wod_suppl_lat : +item.u2wo_lat>0 ? item.u2wo_lat : item.unit_coord_lat,
      // lng: +item.wod_suppl_lng>0 ? item.wod_suppl_lng : +item.u2wo_lng>0 ? item.u2wo_lng : item.unit_coord_lng,
      // unit_coord_lat: item.unit_coord_lat,
      // unit_coord_lng: item.unit_coord_lng, 
      // wod_suppl_lat: item.wod_suppl_lat,
      // wod_suppl_lng: item.wod_suppl_lng,
      // u2wo_lat: item.u2wo_lat,
      // u2wo_lng: item.u2wo_lng,
      photos: item.photos,
      passport: item.passport,
      mpr_id_simple: item.mprd_mpr_id,
      mprdp_id_simple: item.mprdp_id,
      mprdp_deleted_simple: item.mprdp_deleted,
      mprdp_photo_assigned_uf_id_simple: item.mprdp_photo_assigned_uf_id,
      uf_extension_simple: item.uf_extension,
      uf_rand_simple: item.uf_rand,
    }    
  }

  let arr = _addPhotoLinks(filteredMpDetailsSimple);
  // console.log('arr: ', arr);

  return arr.map(item => makeAddress(item));
}

export const retrieveMpDetails = () => async (dispatch) => {
  try {
    console.log('вызов retrieveMpDetails');
    const res = await mpDetailsService.getAll();
    // const unrelatedMprDetails = unrelated ? res.data.filter(item => item.mprd_client_go === 0) : res.data;

    dispatch({
      type: RETRIEVE_MP_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredMpDetails = (filter) => async (dispatch) => {
  try {
    let filteredMpDetails;
    if( !filter || isInitialState(filter) ) {
      filteredMpDetails = [];
    }
    else {
      const res = await mpDetailsService.filter(filter);
      filteredMpDetails = res.data;
    }

    dispatch({
      type: GET_FILTERED_MP_DETAILS,
      payload: filteredMpDetails,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredMpDetailsSimple = (filter) => async (dispatch) => {
  try {
    let filteredMpDetailsSimple;
    if( !filter || isInitialState(filter) ) {
      filteredMpDetailsSimple = [];
    }
    else {
      const res = await mpDetailsService.filterSimple(filter);
      // console.log(' === MP DETAILS ACTION / filterSimple === ', res.data);
      filteredMpDetailsSimple = makeAddressProgrammSimple(res.data); 
    }

    dispatch({
      type: GET_FILTERED_MP_DETAILS_SIMPLE,
      payload: filteredMpDetailsSimple,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getMpDetailsForMonitorings = (filter2) => async (dispatch) => {
 
    const res = await mpDetailsService.getMpDetailsForMonitorings(filter2);
    console.log(' === MP DETAILS ACTION / getMpDetailsForMonitorings === ', res.data);
    const filteredMpDetailsSimple = makeAddressProgrammSimple(res.data); 

    dispatch({
      type: GET_FILTERED_MP_DETAILS_SIMPLE,
      payload: filteredMpDetailsSimple,
    });
};

export const getFilteredMpDetailsWithoutBrand = (filter) => async (dispatch) => {
  try {
    let filteredMpDetailsWithoutBrand;
    if( !filter || isInitialState(filter) ) {
      filteredMpDetailsWithoutBrand = [];
    }
    else {
      const res = await mpDetailsService.filter(filter);
      filteredMpDetailsWithoutBrand = res.data.filter(item => item.mprd_brand === null);
    }

    dispatch({
      type: GET_FILTERED_MP_DETAILS_WITHOUT_BRAND,
      payload: filteredMpDetailsWithoutBrand, 
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateFilteredMpDetail = (mprd_id, mprd_communication_id, mpc_name) => { 
  return {
    type: UPDATE_FILTERED_MP_DETAIL,
    payload: {
      mprd_id,
      mprd_communication_id,
      mpc_name,
    },  
  }
};

export const removeBindedFromFilteredMpDetailsWithoutBrand = (id) => {
  return {
    type: REMOVE_BINDED_MP_DETAIL_FROM_FILTERED_MP_DETAILS_WITHOUT_BRAND,
    payload: id,  // id = mprd_id
  }
};


export const getAllCitiesFromMpDetails = () => async (dispatch) => {
  try {
    const res = await mpDetailsService.allCities();

    dispatch({
      type: GET_ALL_CITIES_FROM_MP_DETAILS, 
      payload: res.data.map(item => _transformCities(item)), // to fit MultiSelect component
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredCitiesFromMpDetails = (filter) => async (dispatch) => {
  if( !filter || isInitialState(filter) ) { 
    return 
  }
  
  try {
    const res = await mpDetailsService.cities(filter);

    dispatch({
      type: GET_FILTERED_CITIES_FROM_MP_DETAILS,
      payload: res.data.map(item => item.mpd_city).sort(), 
    });
  } catch (err) {
    console.log(err);
  }
};

export const getJointCitiesFromMpDetailsByDate = (month) => async (dispatch) => { // MonitoringsTab

  try {
    const res = await mpDetailsService.jointCities(month);
    const cities = res.data.filter(item => item.mp_espar_mm === 1).map(jtem => jtem.mpd_city);
    const cities_simple = res.data.filter(item => item.mp_espar_mm === 0).map(jtem => jtem.mpd_city);
    const cities_joint = cities.filter(item => cities_simple.includes(item));

    dispatch({
      type: GET_JOINT_CITIES_FROM_MP_DETAILS,
      payload: cities_joint,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredUnitTypesFromMpDetails = (filter) => async (dispatch) => { 
  try {
    const res = await mpDetailsService.unitTypes(filter);

    dispatch({
      type: GET_FILTERED_UNIT_TYPES_FROM_MP_DETAILS,
      payload: [...new Set(res.data.map(item => item.mpd_unit_type))].sort(), 
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredClientTypesGoFromMpDetails = (filter) => async (dispatch) => {
  try {
    const res = await mpDetailsService.clientTypesGo(filter);    
    const clientTypesGo = removeDuplicates(res.data.filter(item => item.ctg_name !== null));
    console.log('clientTypesGo: ', clientTypesGo);

    dispatch({
      type: GET_FILTERED_CLIENT_TYPES_GO_FROM_MP_DETAILS,
      payload: clientTypesGo.map(item => _transformClientTypeGo(item)), 
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredClientsGoFromMpDetails = (filter) => async (dispatch) => {
  try {
    const res = await mpDetailsService.clientsGo(filter); 
    const clientsGo = removeDuplicates(res.data.filter(item => item.cg_name !== null)).sort((a,b) => a.cg_name > b.cg_name ? 1 : -1);

    dispatch({
      type: GET_FILTERED_CLIENTS_GO_FROM_MP_DETAILS,
      payload: clientsGo.map(item => _transformClientGo(item)),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getMpDetailsForReport = (selectedFlag, duplicatesFlag) => {
  return {
    type: GET_MP_DETAILS_FOR_REPORT,
    payload: {
      selectedFlag,
      duplicatesFlag,
    }
  }
};