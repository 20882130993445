import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1), 
    },
  },
  }));

  const styles = (theme) => ({
    popper: {
      maxWidth: "fit-content"
    }
  });

  const PopperMy = function (props) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

  const OPTIONS_LIMIT = 100;
// const defaultFilterOptions = createFilterOptions();

// const filterOptions = (options, state) => {
//   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
// };
 
const SelectAllAutocomplete = ({
  style,
  size,
  items,
  selected,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onChange,
  fn,
  array,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selected || []);
  const allSelected = items.length === selectedOptions.length;
  const handleToggleOption = selectedOptions =>
    setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const getOptionLabel = option => `${option.united}`;
  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedOptions(items);
    } else {
      handleClearOptions();
    }
  };

  const classes = useStyles();

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    //console.log('selectedOptions: ', selectedOptions);
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find(option => option.united === "выбрать ВСЕ")) {
        handleToggleSelectAll();
        let result = [];
        result = items.filter(el => el.united !== "select-all");
        //console.log('result: ', result);
        //if(fn) { fn(result) };
        if(fn) { fn([]) };
        return onChange(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        if(fn) { fn(selectedOptions.filter(item => item.united !== 'выбрать ВСЕ')) };
        return onChange(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };
  const optionRenderer = (option, { selected }) => {
    const selectAllProps =
      items.length > 0 && option.united === "выбрать ВСЕ" // To control the state of 'select-all' checkbox
        ? { checked: allSelected } 
        : {};
    return (
      <>
        <Checkbox
          color="secondary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = params => (
    <TextField
      {...params}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      color="secondary"
    />
  );

  const filter = createFilterOptions();
  return (
    <div className={classes.root}>
    <Autocomplete
      PopperComponent={PopperMy}
      size={size}
      style={style}
      multiple
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.united}
      getOptionSelected={(option, value) => option.united === value.united}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        if (items.length > 0) {
          const filtered = filter(options, params);
          return [{ id: 0, united: "выбрать ВСЕ" }, ...filtered].slice(0, OPTIONS_LIMIT);
        } else if (items.length === 0) {
          const filtered = filter(options, params);
          return [...filtered];
        }
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
    </div>
  );
};

SelectAllAutocomplete.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: value => value
};

export default SelectAllAutocomplete;