import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';

import MultiSelectWithAllOptionPlain from '../../components/multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
import Search from '../search/search';

import MonitoringsImageGallery from '../monitoringsImageGallery/monitoringsImageGallery'; 

import {selectClientsGo} from '../../redux/actions/clientsGo';
import {showMonitoringsPhotos, selectMonitoringsPhotos} from '../../redux/actions/mprDetails';

import { RenderCellExpand } from '../../utils/renderCellExpand';

const makeRecord = (item, index) => ({
    id: index,
    photo: item,
});

const columns = [
  { field: 'id', headerName: 'id обычного мониторинга', width: 70, hide: true }, 
  { field: 'maket', headerName: 'Макет', width: 100 },
  { field: 'addressSimple', headerName: 'Адрес обычного мониторинга', renderCell: RenderCellExpand, flex: 1 },
  { field: 'sideSimple', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'typeSimple', headerName: 'Тип констр.', sortable: false, width: 120 },
];

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth:650,
      minHeight: '50vh', 
      flexGrow: 1,
    },
    half: {
      maxHeight: 900,
      // overflow: 'hidden',
      maxWidth: '50vw',
      display: 'flex',
      flexDirection: 'column',
    },
    gallery: {
      display: 'flex',
      //maxHeight: 250,
      maxWidth: 650,
      overflow: 'hidden',
    },
    galleryContainer: {
      maxWidth: 350,
      margin: '0 auto',
    },
  }));

export default function MonitoringsLeftTable(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const {rows, selectedIndex} = props;
    const {mode} = useSelector(state => state.shared);
    const {filteredMprDetailsSimple} = useSelector(state => state.mprDetails);
    
    const filteredMakets =  [...new Set(filteredMprDetailsSimple.map(item => item.maket))].sort();
    
    const [makets, setMakets] = useState([]);
    const [viewedAddressSimple, setViewedAddressSimple] = useState();
    const [search, setSearch] = useState('');

    let rows1;
    switch (selectedIndex) { 
      case 2:   // binded
        rows1 = rows.filter(item => item.mprdp_photo_assigned_uf_id_simple && item.mprdp_deleted_simple !== 1);
        break;
      case 1:   // unbinded
        rows1 = rows.filter(item => !item.mprdp_photo_assigned_uf_id_simple || item.mprdp_deleted_simple === 1 || item.photos.length === 0);
        break;
      default:  // all
        rows1 = rows; 
        break;
    }

    const rows2 = search 
    ? rows1.filter(item =>item.addressSimple.toLowerCase().includes(search.toLowerCase()))
    : rows1;

    const rowsFinalSimple = makets.length>0
      ? rows2.filter(item => makets.includes(item.maket))
      : rows2;

    const handleRowSelection = (e) => {
      const selected = filteredMprDetailsSimple.find(item => item.id === +e.selectionModel);
      console.log('selected / LEFT TABLE: ', selected);
      setViewedAddressSimple(selected);
      dispatch(selectClientsGo([].concat(selected)));
      dispatch(showMonitoringsPhotos([...new Set(selected.photos)].map((item, index) => makeRecord(item, index))));
      dispatch(selectMonitoringsPhotos([])); 
    }

    const onChangeSearch = (e) => {
      setSearch(e.target.value);
    };

    // console.log('rows / LEFT TABLE: ', rows);
   // console.log('mpDetailSimple / LEFT TABLE: ', mpDetailSimple);
    // console.log('rowsFinalSimple / LEFT TABLE: ', rowsFinalSimple);
    // console.log('filteredMakets / LEFT TABLE: ', filteredMakets);
    // console.log('viewedAddressSimple / LEFT TABLE: ', viewedAddressSimple);
    // console.log('filteredMpDetailsSimple / LEFT TABLE: ', filteredMprDetailsSimple);
    // console.log('selectedIndex: / LEFT TABLE', selectedIndex);   

  return (
    <div className={classes.half}>
      <Grid container spacing={1} direction="column" alignItems="flex-start">
        
        <Grid container spacing={1} direction="row" alignItems="center">        
          <Grid item>    
            <MultiSelectWithAllOptionPlain 
              style={{
                width: 300,
                marginLeft: 10,
              }}
              size={'small'}
              limitTags={2}
              label={'Макет'}
              items={filteredMakets}
              value={makets}
              selected={makets}
              onChange={setMakets}
            />
          </Grid>

          <Grid>
            <Search
              label={'Адрес'}
              size={'small'}
              style={{
                width: 200,
                marginLeft: 10,
              }}
              id={'clientGO'}
              value={search}
              searchChange={onChangeSearch} 
            />
          </Grid>
        </Grid>

        <Grid item>
          <DataGrid
            className={classes.table}
            autoHeight
            localeText={ruRU.props.MuiDataGrid.localeText}  
            rows={rowsFinalSimple} 
            columns={columns}
            sortModel={[
              {
                field: 'addressSimple',
                sort: 'asc',
              },
            ]}
            pageSize={5} 
            //checkboxSelection 
            disableColumnMenu
            disableColumnSelector
            //disableSelectionOnClick
            onSelectionModelChange = {handleRowSelection}
            id={"data-grid-left"}
          />
        </Grid>

        {viewedAddressSimple && rowsFinalSimple.length>0 && 
        <Grid item>
          <div className={classes.gallery}>
            <div className={classes.galleryContainer}>
              <MonitoringsImageGallery
                  item={viewedAddressSimple ? viewedAddressSimple : null}     
              />
            </div>
          </div>
        </Grid>}

      </Grid>
    </div>
  );
} 