import {
    RETRIEVE_CITIES,
    GET_CITIES_BY_REPORT,
  } from "./types";
  
  import citiesService from "../../services/citiesService";
  import store from '../store';
  
  
  function _transformCities(city){
    return Object.assign({}, {id: city.city_id, united: city.city_name_ru });    
  }  
  
  export const retrieveCities = () => async (dispatch) => {
    try {
      const res = await citiesService.getAll();
      let cities = res.data.map(item => item.city_name_ru);

      //if(cities.length>100) cities.length = 100;

  
      dispatch({
        type: RETRIEVE_CITIES,
        //payload: res.data.map(item => _transformCities(item)),
        payload: cities.sort(), 
        
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const getCitiesByReport = (mpr_id) => async (dispatch) => { 
    try {
      const res = await citiesService.getCitiesByReport(mpr_id);
      const cities = res.data; 
  
      dispatch({
        type: GET_CITIES_BY_REPORT,
        payload: cities && cities.length>0 
          ? cities.map(item => ({
            label: item.mpd_city,
            value: item.mpd_city_id,
            }))
            : [], 
        
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  
  