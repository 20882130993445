import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    GET_MONTHS_FROM_MP_REPORTS,
    GET_FILTERED_MP_REPORTS,
    RETRIEVE_ACTUAL_REPORTS,
    GET_TIME_PERIOD_ID_BY_REPORT,
    GET_REPORTS_BY_WO_IDS,
  } from "../actions/types";
  
const initialState = {
    filteredMpReports: [],
    monthsFromMpReports: [],
    actualReports: [],
    timePeriodId: '',
};
  
function mpReportsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }

      case GET_MONTHS_FROM_MP_REPORTS:
        if(!payload) return state;
        return {
                ...state,
                monthsFromMpReports: payload,
        }      
      
      case GET_FILTERED_MP_REPORTS:
        if(!payload) return state;
        return {
                ...state,
                filteredMpReports: payload,
        }

      case GET_REPORTS_BY_WO_IDS:
        if(!payload) return state;
        return {
                ...state,
                filteredMpReports: payload,
        }

      case RETRIEVE_ACTUAL_REPORTS:
        if(!payload) return state;
        return {
                ...state,
                actualReports: payload,
        }

      case GET_TIME_PERIOD_ID_BY_REPORT:
        if(!payload) return state;
        return {
                ...state,
                timePeriodId: payload,
        }
            
      default:
        return state;
  };
 }
  
export default mpReportsReducer;