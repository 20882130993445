import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import MultiSelectWithAllOptionPlain from '../multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';

import { getFilteredMpReports } from '../../redux/actions/mpReports';
import { getFilteredMpDetails, getFilteredCitiesFromMpDetails} from '../../redux/actions/mpDetails';
import { setTheFilter} from '../../redux/actions/shared';

import {_baseApiUrl} from '../../redux/constants';
import {getMonthsList, cityNameToId} from '../../utils/shared';


const useStyles = makeStyles((theme) => ({ 
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    dialogcontent: {
      padding: '8px 24px 24px 24px',
    },
    dialogcontainer: {
      marginBottom: '10px',
    },
    success: {
      flexBasis: '75%',
      padding: '14px;',
      marginRight: '0px;',
      color: '#fff',
      // marginBottom: '10px;',
    },
    title: {
      padding: '10px;',
      paddingLeft: '24px;',
    },

  }));

function makeClientGoType(item){
    return {
      id: item.ctg_id,
      united: item.ctg_name,
    }
}

function makeClientGoForFilter(item){
  return {
    cg_id: item.id,
    cg_name: item.united,
  }
}

export default function MaketsDialog2() {
  const classes = useStyles();

  const { allCities, filteredCities } = useSelector(state => state.mpDetails);
  const { filteredClientsGo } = useSelector(state => state.clientsGo);
  const { filteredMpReports} = useSelector(state => state.mpReports);
  
  const {user_role, user_permissions} = useSelector(state => state.users);
 
  const dispatch = useDispatch();
  
  const [open, setOpen] = useState(false);

  const [ctg_id, setClientsTypesGoId] = useState(); 
  const [clientGo, setClientGo] = useState([]);
  const [month, setMonth] = useState();
  // const [mpd_mp_id, setMpdMpId] = useState([]);      // monitoring_projects
  const [mpr_id, setMprId] = useState([]);              // mp_reports
  const [mpd_city, setMpdCity] = useState([]);          // mp_details
  const [mpd_unit_type, setMpdUnitType] = useState([]); // mp_details

  console.log('month: ', month);
  console.log('ctg_id: ', ctg_id);
  console.log('filteredClientsGo: ', filteredClientsGo);
  console.log('clientGo: ', clientGo);
  console.log('filteredMpReports: ', filteredMpReports);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilter = function(dtoObj, property, action){
    return function(newValue){
      dtoObj[property] = newValue;
      console.log('obj', dtoObj);
      dispatch(action(dtoObj));
    }
  };

  const loadPhotos = () => {
    const filter = {
      // ctg_id: ctg_id,
      // mprd_client_go: clientGo.map(item => makeClientGoForFilter(item)),
      mpr_planned_date: month,
      mpr_id: mpr_id,
      mpd_city: mpd_city,
      // mpd_unit_type: mpd_unit_type,
    };

    console.log('filter: ', filter);

    dispatch(getFilteredMpDetails(filter));
    dispatch(setTheFilter(filter));

    handleClose();
  }

  return (
    <div>
      <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>

        <Grid item>
        <Tooltip title="Месяц -> Город -> Отчет">
          <Button
              variant="contained"
              size="large"
              color="secondary" 
              className={classes.success}
              onClick={handleClickOpen}
          >
              Фильтр 2
          </Button>
          </Tooltip>
        </Grid>


        <Grid item>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.title}>Фильтр</DialogTitle>
          
          <DialogContent className={classes.dialogcontent}>

              <form className={classes.container}>
                <Grid container spacing={1} display="flex" direction="column"  wrap="nowrap" alignItems="baseline">

                  <Grid item>
                  <MultiSelectWithAllOptionPlain
                      style={{
                        width: 550,
                      }}
                      label={'Месяц'}
                      items={user_role === 'Admin' ? getMonthsList(12) : getMonthsList(12).filter(item => user_permissions.months.includes(item))}
                      limitTags={5}
                      value={month}
                      selected={month}
                      onChange={setMonth} 
                      fn={getFilter(
                        {
                          mpr_planned_date: [],
                        },
                        'mpr_planned_date',
                        getFilteredCitiesFromMpDetails,
                        dispatch 
                      )}
                    />              
                  </Grid>

                  {filteredCities.length>0 && 
                  <Grid item>
                      <MultiSelectWithAllOptionPlain 
                          style={{
                            width: 550,
                          }}
                          label={'Город'}
                          items={user_role === 'Admin' ? filteredCities : filteredCities.filter(item => user_permissions.cities.includes(cityNameToId(item, allCities)))}
                          value={mpd_city}
                          selected={mpd_city}
                          onChange={setMpdCity}
                          fn={getFilter(
                            {                         
                              mpr_planned_date: month,
                              mpd_city: [],                                
                            },
                            'mpd_city',
                            getFilteredMpReports
                          )}
                      />
                  </Grid>}

                  {filteredCities.length>0 && filteredMpReports.length >0 &&
                  <Grid item>
                    <MultiSelectWithAllOptionPlain
                      style={{
                        width: 550,
                      }}
                      label={'Отчеты'}
                      items={user_role === 'Admin' ? filteredMpReports : filteredMpReports.filter(item => user_permissions.reports.includes(item))}
                      limitTags={5}
                      value={mpr_id}
                      selected={mpr_id}
                      onChange={setMprId} 
                    />
                  </Grid>}
                                 
                </Grid>
              </form>
          </DialogContent>       

          
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Отмена
            </Button>

            {filteredCities.length>0 && filteredMpReports.length >0 && mpr_id.length >0 &&
            <Button 
              color="secondary"
              onClick={loadPhotos}
            >
              Загрузить фотографии
            </Button>}
            
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
      
    </div>
  );
}