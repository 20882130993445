import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { lightBlue } from '@material-ui/core/colors'; 
import Grid from '@material-ui/core/Grid';
import SingleSelectNative from '../singleSelectNative/singleSelectNative';
import MultiSelectWithAllOptionPlain from '../multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
import MultiSelectWithAllOption from '../multiSelectWithAllOption/multiSelectWithAllOption';

import { getMprDetailsForMonitoringsFilter1 } from '../../redux/actions/mprDetails';
import { getTimePeriodByReport } from '../../redux/actions/mpReports';
import { getFilteredClientsGoFromMpDetails } from '../../redux/actions/mpDetails';
import { getCitiesByReport } from '../../redux/actions/cities';

import {_baseApiUrl} from '../../redux/constants';


const useStyles = makeStyles((theme) => ({  
    root: {
      display: 'flex',
      flexDirection: 'row', 
      fleWrap: 'nowrap',
    },      
    success: {
        flexBasis: '75%',
        padding: '14px;',
        marginRight: '0px;',
        color: '#fff',
        // marginBottom: '10px;',
    },
    export: {
      color: 'black',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        backgroundColor: lightBlue[700],
        color: '#fff',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      marginBottom: '20px;',
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    title: {
      padding: '10px;',
      paddingLeft: '24px;',
    },
    dialogcontent: {
      padding: '8px 24px 24px 24px',
    },
    dialogcontainer: {
      marginBottom: '10px',
    },

  }));

  function makeClientGoType(item){
    return {
      id: item.ctg_id,
      united: item.ctg_name,
    }
  }

export default function MonitoringsDialog1() {
  const classes = useStyles();

  const { actualReports, filteredMpReports } = useSelector(state => state.mpReports);
  const {cities} = useSelector(state => state.cities);
  const { filteredClientsGoFromMpDetails } = useSelector(state => state.mpDetails);
  
  
  const {user_role, user_permissions} = useSelector(state => state.users);

  const dispatch = useDispatch();
  
  const [open, setOpen] = useState(false);  
  
  const [simple_mpr_id, setSimpleMprId] = useState(''); // mp_reports ОБЫЧНЫЙ
  const [mpd_city, setMpdCity] = useState('');          // mp_details  
  const [mpr_id, setMprId] = useState('');             // mp_reports МАССОВЫЙ
  const [clientGo, setClientGo] = useState([]);       // clientGo для режима Авто для выбранных mp_reports МАССОВЫХ
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilter = function(dtoObj, property, action){
    return function(newValue){
      dtoObj[property] = newValue;
      console.log('obj', dtoObj);
      dispatch(action(dtoObj));
    }
  };

  const getOther = function(dtoObj, action){
    return function(){
      console.log('obj', dtoObj);
      dispatch(action(dtoObj));
    }
  };

  const loadData = () => {

    const data = {
      simple_mpr_id: simple_mpr_id,
      mpr_id: mpr_id,
      mpd_city_id: mpd_city,
      mprd_client_go: clientGo.map(item => item.cg_id),     
    };

    dispatch(getMprDetailsForMonitoringsFilter1(data));

    handleClose();
  }

      // console.log('data: ', data);
  // console.log('month: ', month);
  // console.log('mpd_city: ', mpd_city);
  // console.log('simple_mpr_id: ', simple_mpr_id);
  // console.log('cities: ', cities);
    // console.log('clientGo: ', clientGo);
  // console.log('timePeriodId: ', timePeriodId);
  // console.log('mpr_id: ', mpr_id);
  // console.log('filteredClientsGoFromMpDetails: ', filteredClientsGoFromMpDetails);
  


  return (
    <div>
      <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>

        <Grid item>
        <Tooltip title="Месяц -> Город -> Отчет обычного мониторинга -> Отчет масс мониторинга">
          <Button
              variant="contained"
              size="large"
              color="secondary" 
              className={classes.success}
              onClick={handleClickOpen}
          >  
              АВТО
          </Button>
          </Tooltip>
        </Grid>


        <Grid item>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" className={classes.title}>Фильтр</DialogTitle>
          
          <DialogContent className={classes.dialogcontent}>

              <form className={classes.container}>
                <Grid container spacing={1} display="flex" direction="column"  wrap="nowrap" alignItems="baseline">

                  <Grid item>
                    <SingleSelectNative
                      label={"Обычный мониторинг"}
                      style={{width: 350}}
                      value={simple_mpr_id}
                      setValue={setSimpleMprId}
                      options={actualReports}
                      fn={getFilter(
                        {
                          mpr_id: '',
                        },
                        'mpr_id',
                        getCitiesByReport
                      )}                  
                    />
                  </Grid>

                  {cities.length>0 && simple_mpr_id &&
                  <Grid item>
                    <SingleSelectNative
                      label={"Город"}
                      style={{width: 350}}
                      value={ mpd_city}
                      setValue={setMpdCity}
                      options={cities}
                      fn={getOther(
                        {
                          mpr_id: simple_mpr_id,
                        },
                        getTimePeriodByReport
                      )}                  
                    />
                  </Grid>}

                  {filteredMpReports.length>0 && cities.length>0 && simple_mpr_id &&
                  <Grid item>
                    <MultiSelectWithAllOptionPlain
                      label={"Масс Мониторинг"}
                      style={{width: 350}}
                      value={mpr_id}
                      selected={mpr_id}
                      onChange={setMprId}
                      items={filteredMpReports}
                      //items={user_role === 'Admin' ? filteredMpReports : filteredMpReports.filter(item => user_permissions.reports.includes(item))}
                      fn={getFilter(
                        { 
                          mpd_city: [].concat(cities.find(item => item.value === mpd_city).label),
                          simple_mpr: 'false',
                          mpr_id: [], 
                        },
                        'mpr_id',
                        getFilteredClientsGoFromMpDetails
                      )}
                      array={true} 
                    />
                  </Grid>}

                  {filteredClientsGoFromMpDetails.length>0 && 
                  <Grid item>
                    <MultiSelectWithAllOption
                        style={{
                          width: 550,
                        }}
                        label={'Client_Go (из масс.мониторинга)'}
                        items={filteredClientsGoFromMpDetails}
                        value={clientGo}
                        selected={clientGo}
                        onChange={setClientGo}
                    />
                  </Grid>}
             
                </Grid>
              </form>
          </DialogContent>
          
          <DialogActions>
            <Button 
              onClick={handleClose} 
              color="primary"
            >
              Отмена
            </Button>

            {clientGo.length>0 && 
            <Button 
              color="secondary"
              onClick={loadData}
            >
              Загрузить данные
            </Button>}
            
          </DialogActions>
        </Dialog>
      </Grid>

    </Grid>
      
    </div>
  );
}