import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid'; 

import MonitoringsDialog1 from '../../components/monitoringsDialogs/monitoringsDialog1';
import MonitoringsDialog2 from '../../components/monitoringsDialogs/monitoringsDialog2';
import MonitoringsActions from '../../components/monitoringsActions/monitoringsActions';
import MonitoringsContainer from '../../components/monitoringsContainer/monitoringsContainer';

import {retrieveActualReports} from '../../redux/actions/mpReports';
import { resetTheStore } from '../../redux/actions/shared';

import {_baseApiUrl} from '../../redux/constants';
import Spinner from '../../components/spinner/spinner';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
      },
  }));

const Monitorings = () => { 
    const classes = useStyles();

    const dispatch = useDispatch();

    const {isLoading} = useSelector(state => state.shared);
    const {autoTableData} = useSelector(state => state.mprDetails);
    
    useEffect(() => {
        dispatch(resetTheStore());  
    }, []);

    useEffect(() => {
        dispatch(retrieveActualReports());
    }, []);

    const {mode} = useSelector(state => state.shared); 
    
    return isLoading === 'Yes'
        ? (
            <div>
                <Grid container>
                    <MonitoringsDialog1 /> 
                    <MonitoringsDialog2 />
                    <Spinner />
                </Grid>
            </div>
            )
        :  mode === ''
        ? (
            <div>
                <Grid container>
                    <MonitoringsDialog1 /> 
                    <MonitoringsDialog2 />
                    
                </Grid>
            </div>
        )
        : (
                <div>
                    <Grid container>
                        <MonitoringsDialog1 /> 
                        <MonitoringsDialog2 />
                        <MonitoringsActions />
                    </Grid>
                    
                    <MonitoringsContainer />

                </div>
        )             
}

export default Monitorings; 