import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  imagelistitem: {
    margin: '0 auto',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginBottom: '20px;',
  },
  dialog: {
    overflowY: 'unset',
  },
  button: {
    padding: 15,
  },
  link: {
    display: 'block',
    width: 333, 
    height: 250,
    padding: 15,
  }, 
}));


export default function MonitoringsImage(props) {
  const classes = useStyles();
  const {item, height, width, title, subtitle} = props;

  const [open, setOpen] = useState(false);   // open fullWidth image
  
  const handleDoubleClick = (e) => {
    setOpen((prev) => !prev);
  };

  console.log('PHOTO / MonitoringsImage: ', item);

  return item && item.photo && item.photo.length>0
    ? (
        <div
          className={classes.imagelistitem}
          onDoubleClick={handleDoubleClick}
        >
          <Dialog
              classes={{paper: classes.dialog}}
              aria-labelledby="simple-dialog-title" 
              open={open}
          >
            <img src={item.photo}/>
          </Dialog>
            
            <img
              src={item.photo}
              alt={'Фото мониторинга'}
              height={height ? height : 'auto'}
              width={width ? width : 'auto'}
            />
        </div>
      ) 
    : item.passport.substr(-3) !== 'jpg'
      ? (
        <a
          target="_blank" 
          href={item && item.passport ? item.passport : 'https://via.placeholder.com/333x250'}
          rel="noopener noreferrer"
          className={classes.link}
        >  
          <Typography variant="h5" align="center" className={classes.button}>
            Смотреть паспорт
            <Typography variant="subtitle2" align="center"> 
                {item && item.passport ? item.passport : 'https://via.placeholder.com/333x250'}
            </Typography>
          </Typography>
        </a>       
      )
      : (
          <div
            className={classes.imagelistitem}
            onDoubleClick={handleDoubleClick}
          >
            <Dialog
              classes={{paper: classes.dialog}}
              aria-labelledby="simple-dialog-title" 
              open={open}
            >
              <img src={item.passport}/>
            </Dialog>
              
              <img
                src={item.passport}
                alt={'Фото мониторинга'}
                height={height ? height : 'auto'}
                width={width ? width : 'auto'}
              />
          </div>
    ) 
}
