import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/mp_reports`, { headers: authHeader() }); 
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mp_reports/${id}`, { headers: authHeader() })
}

const filter = (filter) => {
    return axios.post(_baseApiUrl + `/mp_reports/filter`, filter, { headers: authHeader() });
};

const getActualReports = () => {
    return axios.get(_baseApiUrl + `/mp_reports/actual_reports`, { headers: authHeader() });  
};

const timePeriodIdByReport = (mpr_id) => {
    return axios.get(_baseApiUrl + `/mp_reports/time_period_id/${mpr_id.mpr_id}`, { headers: authHeader() })
}

const getReportsByWoIds = (wo_ids) => {
    return axios.post(_baseApiUrl + `/mp_reports/by_wo_ids`, wo_ids, { headers: authHeader() })
}
 
export default {
    getAll,
    get,
    getActualReports,
    getReportsByWoIds,
    filter,    
    timePeriodIdByReport,
};