import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_MPR_DETAILS,
    GET_FILTERED_MPR_DETAILS,
    GET_FILTERED_MPR_DETAILS_SIMPLE,
    // GET_MP_COMMUNICATION_FROM_MPR_DETAIL,
    UPDATE_ONE_MPR_DETAIL,
    UPDATE_MANY_FILTERED_MPR_DETAILS,   
    SELECT_UNRELATED,
    GET_BRANDS_FROM_MPR_DETAILS,
    GET_BRANDS,
    GET_BRANDS_FOR_BRANDS,
    GET_BRANDS_RELATED,
    SELECT_BRANDS,
    BIND_PHOTO_TO_THE_CLIENTS_GO,
    BIND_BRANDS_TO_THE_CLIENTS_GO,
    GET_MONTHS_RELATED_TO_THE_CLIENT,
    SELECT_MPR_DETAILS,
    GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE,
    GET_MPR_DETAILS_FOR_MONITORINGS,
    SET_AUTO_TABLE_DATA,
    UPDATE_FILTERED_MPR_DETAILS_SIMPLE,
    SELECT_MONITORINGS_AUTO_TABLE_ADDRESSES,
    UPDATE_AUTO_TABLE_DATA,
    SHOW_MONITORINGS_PHOTOS,
    SELECT_MONITORINGS_PHOTOS,
  } from "../actions/types"; 

  import { takeUfId } from "../../utils/shared";
  
  const initialState = {
    unrelated: true,
    mprDetails: [],
    filteredMprDetails: [],
    filteredMprDetailsSimple: [],
    autoTableData: [],
    monitoringsAutoTableAddresses: [],
    monitoringsPhotos: [],
    selectedMonitoringsPhotos: [],
    brandsFromMprDetails: [],
    brands: [],
    selectedBrands: [],
    months: [],
    updatedMprDetail: {},
    selectedMprDetails: [],
    brandsForBrands: [], 
    // mpCommunicationFromMprDetail: '',
  };
  
  function mprDetailsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
  
      case RETRIEVE_MPR_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                mprDetails: payload
        }
      
      case GET_FILTERED_MPR_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredMprDetails: payload
        }

      case GET_FILTERED_MPR_DETAILS_SIMPLE:
        if(!payload) return state;
        return {
                ...state,
                filteredMprDetailsSimple: payload
        }
      
      // case GET_MP_COMMUNICATION_FROM_MPR_DETAIL:
      //   if(!payload) return state;
      //   return {
      //           ...state,
      //           mpCommunicationFromMprDetail: payload
      //   }

      case UPDATE_ONE_MPR_DETAIL:
        if(!payload) return state;

        const index = state.mprDetails.findIndex(item => item.mprd_id === payload.mprd_id);
        const newArray = [...state.mprDetails]; //making a new array
        for (const property in newArray[index]) {
          newArray[index][property] = payload[property];
        }

        return { 
            ...state,              //copying the orignal state
            mprDetails: newArray,  //reassingning to new array
        }

      case UPDATE_MANY_FILTERED_MPR_DETAILS: // only mprd_client_go prop!
        if(!payload) return state;

        //const index = state.mprDetails.findIndex(item => item.mprd_id === payload.mprd_id);
        const newFilteredMprDetailsArray = [...state.mprDetails]; //making a new array
        for (let i = 0; i<payload.length; i++) {
          const index = newFilteredMprDetailsArray.findIndex(el => el.mprd_id === payload[i].mprd_id);
          newFilteredMprDetailsArray[index].mprd_client_go = payload[i].mprd_client_go;
        }

        return { 
            ...state,              //copying the orignal state
            mprDetails: newFilteredMprDetailsArray,  //reassingning to new array
        }
      
        
      case GET_BRANDS_FROM_MPR_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                brandsFromMprDetails: payload
        }

      case GET_BRANDS:
      if(!payload) return state;
      return {
              ...state,
              brands: payload
      }

      case GET_BRANDS_FOR_BRANDS:
        if(!payload) return state;
        return {
                ...state,
                brandsForBrands: payload
        }

      case GET_BRANDS_RELATED:
        if(!payload) return state;
        return {
                ...state,
                brands: payload
        }
      
      case GET_MONTHS_RELATED_TO_THE_CLIENT:
        if(!payload) return state;
        return {
                ...state,
                months: payload.sort()
        }
      
      case SELECT_BRANDS:
        if(!payload) {
          return {
            ...state,
            selectedBrands: [],
          }
        }
        else {
          return {
            ...state,
            selectedBrands: payload,
          }        
        }

      case SELECT_UNRELATED:
        return {
                ...state,
                unrelated: payload,
      }  

      case BIND_PHOTO_TO_THE_CLIENTS_GO:
        if(!payload) return state;
        return {
                ...state,
                updatedMprDetail: Object.assign({}, payload),
        }     
  
      case BIND_BRANDS_TO_THE_CLIENTS_GO:
        return {
          ...state,
          selectedBrands: []
        }

      case SELECT_MPR_DETAILS:
        return {
          ...state,
          selectedMprDetails: payload,
        }
        
      case GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE:
        return {
          ...state,
          filteredMprDetailsSimple: payload,
        }

      case GET_MPR_DETAILS_FOR_MONITORINGS:
        return {
          ...state,
          filteredMprDetails: payload,
        }

      case SET_AUTO_TABLE_DATA:
        return {
          ...state,
          autoTableData: payload,
        }

      case UPDATE_FILTERED_MPR_DETAILS_SIMPLE:   // update prop.: photos
        if(!payload) return state;

        const mprdIndexSimple = state.filteredMprDetailsSimple.findIndex(item => item.mprd_id_simple === payload.id); 
        console.log('mprdIndexSimple / MPR DETAILS REDUCER: ', mprdIndexSimple);
        const oldPhotos = [].concat(state.filteredMprDetailsSimple[mprdIndexSimple].photos);
        console.log('oldPhotos / MPR DETAILS REDUCER: ', oldPhotos);
        console.log('payload.photos / MPR DETAILS REDUCER: ', payload.photos);
        const newArraySimple = [...state.filteredMprDetailsSimple];

        const newPhotos = payload.photos.length === 0 && payload.ufIdToUnbind 
          ? oldPhotos.filter(item => takeUfId(item) !== payload.ufIdToUnbind)
          : [].concat(oldPhotos, payload.photos);         
        console.log('newPhotos / MPR DETAILS REDUCER', newPhotos);

        newArraySimple[mprdIndexSimple].photos = [].concat(newPhotos);
        newArraySimple[mprdIndexSimple].mprdp_photo_assigned_uf_id_simple = payload.mprdp_photo_assigned_uf_id;
        newArraySimple[mprdIndexSimple].mprdp_deleted_simple = newPhotos.length > 0
          ? 0
          : payload.mprdp_deleted;
        console.log('newArraySimple[mprdIndexSimple] / MPR DETAILS REDUCER: ', newArraySimple[mprdIndexSimple]);
        
        return {
                ...state,
                filteredMprDetailsSimple: newArraySimple
      }

      case UPDATE_AUTO_TABLE_DATA:  
      if(!payload) return state;

      const mprdIndexSimpleAutoTable = state.autoTableData.findIndex(item => item.mprd_id_simple === payload.id); 
      console.log('AUTO TABLE mprdIndexSimple / MPR DETAILS REDUCER: ', mprdIndexSimpleAutoTable);
      const oldPhotosAutoTable = [].concat(state.autoTableData[mprdIndexSimpleAutoTable].photos);
      console.log('AUTO TABLE oldPhotos / MPR DETAILS REDUCER: ', oldPhotosAutoTable);
      console.log('AUTO TABLE payload.photos / MPR DETAILS REDUCER: ', payload.photos);
      const newArraySimpleAutoTable = [...state.autoTableData];
      const newPhotosAutoTable =  [].concat(oldPhotosAutoTable, payload.photos);
      console.log('AUTO TABLE newPhotos / MPR DETAILS REDUCER', newPhotosAutoTable);
      newArraySimpleAutoTable[mprdIndexSimpleAutoTable].photos = [].concat(newPhotosAutoTable);
      newArraySimpleAutoTable[mprdIndexSimpleAutoTable].mprdp_photo_assigned_uf_id_simple = payload.mprdp_photo_assigned_uf_id;
      newArraySimpleAutoTable[mprdIndexSimpleAutoTable].mprdp_deleted_simple = payload.mprdp_deleted;
      newArraySimpleAutoTable[mprdIndexSimpleAutoTable].uf_extension_simple = newArraySimpleAutoTable[mprdIndexSimpleAutoTable].uf_extension;
      newArraySimpleAutoTable[mprdIndexSimpleAutoTable].uf_rand_simple = newArraySimpleAutoTable[mprdIndexSimpleAutoTable].uf_rand;
      console.log('AUTO TABLE newArraySimple[mprdIndexSimpleAutoTable] / MPR DETAILS REDUCER: ', newArraySimpleAutoTable[mprdIndexSimpleAutoTable]);
      return {
              ...state,
              autoTableData: newArraySimpleAutoTable
    }

      case SELECT_MONITORINGS_AUTO_TABLE_ADDRESSES:
        if(!payload) return state;
        return {
                ...state,
                monitoringsAutoTableAddresses: [].concat(payload)
        }
        
      case SHOW_MONITORINGS_PHOTOS:
        if(!payload) return state;
        return {
                ...state,
                monitoringsPhotos: payload
        }
        
      case SELECT_MONITORINGS_PHOTOS:
        if(!payload) return state;
        return {
                ...state,
                selectedMonitoringsPhotos: payload
        }

  
      default:
        return state;
    }
  };
  
  export default mprDetailsReducer;