import {
  GET_MONTHS_FROM_MP_REPORTS,
  GET_FILTERED_MP_REPORTS,
  GET_FILTERED_MONITORING_PROJECTS,
  RETRIEVE_ACTUAL_REPORTS,
  GET_TIME_PERIOD_ID_BY_REPORT,
  GET_WO_IDS_BY_TIME_PERIOD_ID,
  GET_REPORTS_BY_WO_IDS,
  } from "./types"; 
  
import mpReportsService from '../../services/mpReports';
import webOrdersService from '../../services/webOrdersService';


export const getFilteredMpReports = (data) => async (dispatch) => {
  try {
    const res = await mpReportsService.filter(data);
 
    dispatch({
      type: GET_FILTERED_MP_REPORTS,
      payload: [...new Set(res.data.map(item => item.mpr_id))].sort((a, b) => a > b ? -1 : 1),
    });
  } catch (err) {
    console.log(err);
  }
};

export const getMonthsFromMpReports = (data) => async (dispatch) => { 
  try {
    const res = await mpReportsService.filter(data);

    dispatch({
      type: GET_MONTHS_FROM_MP_REPORTS,
      payload: [...new Set(res.data.map(item => item.mpr_planned_date.substring(0,7)))].sort((a, b) => a > b ? 1 : -1), // ? 
    });
  } catch (err) {
    console.log(err);
  }
};

export const retrieveActualReports = () => async (dispatch) => {
  try {
    const res = await mpReportsService.getActualReports();
    console.log('response actual reportts: ', res.data)
 
    dispatch({
      type: RETRIEVE_ACTUAL_REPORTS, 
      payload: res.data.length > 0 
        ? res.data.map(item => ({
          label: String(item.mpr_id),
          value: item.mpr_id,
        }))
        : []
    });
  } catch (err) {
    console.log(err);
  }
};

export const getTimePeriodByReport = (mpr_id) => async (dispatch) => {
  try {
    const res = await mpReportsService.timePeriodIdByReport(mpr_id);

    dispatch({
      type: GET_TIME_PERIOD_ID_BY_REPORT,
      payload: res.data[0].wo_period_start
    });

    const dto2 = {
      wod_time_period_id: res.data[0].wo_period_start
    }
 
    const res2 = await webOrdersService.getWoIdsByTimePeriodId(dto2);
    const wo_ids = res2.data.map(item => item.wo_id);

    dispatch({
      type: GET_WO_IDS_BY_TIME_PERIOD_ID,
      payload: wo_ids,
    });

    const dto3 = {
      wo_ids: wo_ids
    }

    const res3 = await mpReportsService.getReportsByWoIds(dto3);
    const mpr_ids = res3.data; 

    dispatch({
      type: GET_REPORTS_BY_WO_IDS,
      payload: mpr_ids.length > 0 ? mpr_ids.map(item => item.mpr_id) : []
      // payload: mpr_ids.length > 0 
      //   ? mpr_ids.map(item => ({
      //     label: String(item.mpr_id),
      //     value: item.mpr_id,
      //   }))
      //   : []
    });


  } catch (err) {
    console.log(err);
  }
};

