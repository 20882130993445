import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';

import MonitoringsPhotos from '../monitoringsPhotos/monitoringsPhotos';
import MonitoringsImage from '../monitoringsImage/monitoringsImage';
import MonitoringsImageGallery from '../monitoringsImageGallery/monitoringsImageGallery';

import Search from '../search/search';
import MultiSelectWithAllOptionPlain from '../../components/multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';

import {selectMonitoringsAutoTableAddresses} from '../../redux/actions/mprDetails'; 

import { RenderCellExpand } from '../../utils/renderCellExpand';
import { to7digitals } from '../../utils/shared';

const columns = [
  { field: 'id', headerName: 'id обычного мониторинга', width: 70, hide: true },
  { field: 'maket', headerName: 'Макет', width: 100,  flex: 0.2 },
  { field: 'addressSimple', headerName: 'Адрес обычного мониторинга', width: 350, sortable: false, renderCell: RenderCellExpand},
  { field: 'sideSimple', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'typeSimple', headerName: 'Тип констр.', sortable: false, width: 120 },
  { field: 'mid', headerName: 'id масс мониторинга', width: 70, hide: true },
  { field: 'address', headerName: 'Адрес масс. мониторинга', width: 350, sortable: false, renderCell: RenderCellExpand },
  { field: 'side', headerName: 'Ст.', sortable: false, width: 60 },
  { field: 'type', headerName: 'Тип констр.', sortable: false, width: 120},
  { field: 'clientGo', headerName: 'Client_Go', width: 100, flex: 0.2}
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    table: {
        flexGrow: 1,
    },
    photoBlock: {
      width: 1300, 
      margin: '0 auto',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    halfPhotoBlock: {
      display: 'flex',      
      //height: 300,
      margin: '0 auto',
      padding: 14,
      flex: '0 1 100%;',
    },
    gallery: {
      display: 'block',
      height: 350,

    },
    onePhoto: {
      display: 'flex',
      flexWrap: 'nowrap',
      margin: '0 auto',
    }
  }));

export default function MonitoringsAutoTable(props) {
    const classes = useStyles();

    const {filteredMprDetailsSimple, filteredMprDetails} = useSelector(state => state.mprDetails);

    const filteredMakets =  [...new Set(filteredMprDetailsSimple.map(item => item.maket))].sort();

    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [viewedAddress, setViewedAddress] = useState();
    const [viewedAddressSimple, setViewedAddressSimple] = useState();
    const [makets, setMakets] = useState([]);
    const [search, setSearch] = useState('');

    const {rows, selectedIndex} = props;

    let rows1; 
    switch (selectedIndex) { 
      case 2:  // binded
        rows1 = rows.filter(item => item.mprdp_photo_assigned_uf_id_simple && item.mprdp_deleted_simple !== 1);
        break;
      case 1:  // unbinded
        rows1 = rows.filter(item => !item.mprdp_photo_assigned_uf_id_simple || item.mprdp_deleted_simple === 1 || item.photos.length === 0);
        break;
      default: // all                
        rows1 = rows; 
        break;
    }

    const rows2 = search
    ? rows1.filter(item =>item.addressSimple.toLowerCase().includes(search.toLowerCase()))
    : rows1;

    const rowsFinalSimple = makets.length>0
    ? rows2.filter(item => makets.includes(item.maket))
    : rows2;
    
    const dispatch = useDispatch();

    const mprDetailSimple = viewedAddressSimple 
    ? filteredMprDetailsSimple.find(item => item.mprd_id_simple === viewedAddressSimple)
    : null;

    const mprDetail = viewedAddress
    ? filteredMprDetails.find(item => item.mprd_id === viewedAddress)
    : null;

    const item = mprDetail 
      ? {photo: "https://monitoring.graceoutdoor.ru/" + to7digitals(mprDetail.mprd_mpr_id) + "/" + to7digitals(mprDetail.mprdp_photo_assigned_uf_id) + "_" + to7digitals(mprDetail.uf_rand) + "_900." + mprDetail.uf_extension, passport: mprDetail.mpd_photo_url} 
      : undefined;

    const onChangeSearch = (e) => {
      setSearch(e.target.value);
    };

    console.log('rows / AUTO TABLE: ', rows);
    console.log('search / AUTO TABLE: ', search);
    console.log('rowsFinalSimple / AUTO TABLE: ', rowsFinalSimple);
    console.log('selectedAddresses / AUTO TABLE: ', selectedAddresses);
    console.log('viewedAddress / AUTO TABLE: ', viewedAddress);
    console.log('viewedAddressSimple / AUTO TABLE: ', viewedAddressSimple);
 
    console.log('mprDetailSimple / AUTO TABLE: ', mprDetailSimple);
    console.log('mprDetail / AUTO TABLE: ', mprDetail);
    console.log('item / AUTO TABLE: ', item);
    

  return rows.length>0 
    ? (
    <div className={classes.root}>

      <Grid container spacing={1} direction="row" alignItems="center">  
      
        <Grid item>
          <MultiSelectWithAllOptionPlain
              style={{
                width: 300,
                marginLeft: 10,
              }}
              size={'small'}
              limitTags={2}
              label={'Макет'}
              items={filteredMakets}
              value={makets}
              selected={makets}
              onChange={setMakets}
          />
        </Grid>

        <Grid>
          <Search
            label={'Адрес'}
            size={'small'}
            style={{
              width: 200,
              marginLeft: 10,
            }}
            id={'clientGO'}
            value={search}
            searchChange={onChangeSearch} 
          />
        </Grid>
      
      </Grid>


      <DataGrid
        className={classes.table}
        autoHeight
        localeText={ruRU.props.MuiDataGrid.localeText} 
        rows={rowsFinalSimple} 
        columns={columns}
        sortModel={[
          {
            field: 'addressSimple',
            sort: 'asc',
          },
        ]}
        pageSize={5} 
        checkboxSelection
        disableColumnMenu
        disableSelectionOnClick
        onRowClick={(e) => {
          setViewedAddress(e.row.mid);
          setViewedAddressSimple(e.row.mprd_id_simple)
        }}

        onSelectionModelChange={(newSelectionModel) => {
          setSelectedAddresses(rows.filter(item => newSelectionModel.includes(item.id)));
          dispatch(selectMonitoringsAutoTableAddresses(rows.filter(item => newSelectionModel.includes(item.id))));
        }}
        selectionModel={selectedAddresses.map(item => item.id)} 
        />

        <div className={classes.photoBlock}>

            <div className={classes.halfPhotoBlock}>
              <div className={classes.gallery}>
              {viewedAddressSimple && 
                <MonitoringsImageGallery
                    item={mprDetailSimple}
                    height={250}
                />}
              </div>
            </div> 

            <div className={classes.halfPhotoBlock}>
              <div className={classes.onePhoto}>
              {viewedAddress && item &&  
                <MonitoringsImage
                  item={item}
                  height={250}
                  width={333} 
                />}
              </div>
            </div>    
       

        </div>
    </div>
  )

  : (
    <div>Loading ...</div>
  )
}


{/* <Grid container spacing={2} style={{maxHeight: 250}}>

<Grid item>
  <div className={classes.gallery}>
    {viewedAddressSimple && 
      <MonitoringsImageGallery
          item={mprDetailSimple}
          height={250}
      />}
  </div>
</Grid> 

<Grid item> 
  {viewedAddress && item &&  
  <MonitoringsImage
    item={item}
    height={250}
    width={333} 
  />}
</Grid>        

</Grid> */}