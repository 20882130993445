import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_CITIES,
    GET_CITIES_BY_REPORT,
  } from "../actions/types";
  
  const initialState = {
    cities: [],
  };
  
  function citiesReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState        
        }


      case RETRIEVE_CITIES:
        if(!payload) return state; 
        return {
                ...state,
                cities: payload,
        }

      case GET_CITIES_BY_REPORT:
        if(!payload) return state; 
        return {
                ...state,
                cities: payload,
        }
  
      default:
        return state;
    }
  };
  
  export default citiesReducer;