import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function SingleSelectNative(props) {
  const classes = useStyles();

  const {label, style, options, value, setValue, fn, array} = props; 

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log('new Value: ', event.target.value);

    if(fn) {
        if(array) {
            fn([].concat(event.target.value)) 
        }
        else {
            fn(event.target.value)
        }               
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      
    >
      <div className={classes.root}>
        <TextField
          id="outlined-select-currency"
          select
          label={label}
          style={style}
          value={value}
          onChange={handleChange}
          variant="outlined"            
          color="secondary"
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
       
      </div>      
    </Box>
  );
}
