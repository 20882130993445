import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {DataGrid, ruRU} from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import MonitoringsImage from '../monitoringsImage/monitoringsImage';

import { selectMonitoringsPhotos } from '../../redux/actions/mprDetails';

const columns = [
  { field: 'id', headerName: 'id обычного мониторинга', width: 70, hide: true }, 
  { field: 'photo', headerName: 'Фото', width: 100, flex: 1 },
];

const useStyles = makeStyles((theme) => ({ 
    table: {
      marginTop: 56,
      minWidth: 650,
      minHeight: '50vh', 
      flexGrow: 1,
    },
    half: {
      maxHeight: '900px;',
      //overflow: 'hidden',
      maxWidth: '50vw',
      display: 'flex',
    },
  }));


export default function MonitoringsRightUnbindTable(props) {
    const classes = useStyles(); 
    const dispatch = useDispatch();


    const {monitoringsPhotos, selectedMonitoringsPhotos} = useSelector(state => state.mprDetails);
    const {rows} = props;

    const [photoToShow, setPhotoToShow] = useState('');

    //console.log('photoToShow / MonitoringsRightTableToUnbind: ', photoToShow);
    //console.log('monitoringsPhotos / MonitoringsRightTableToUnbind: ', monitoringsPhotos);

  return (
    <div className={classes.half}>
  
      <Grid container spacing={1} direction="column" alignItems="flex-start">
          
            {monitoringsPhotos.length>0 &&
            <Grid item>
              <DataGrid
                  className={classes.table}
                  autoHeight
                  localeText={ruRU.props.MuiDataGrid.localeText} 
                  rows={rows} 
                  columns={columns}
                  pageSize={5} 
                  checkboxSelection 
                  disableColumnMenu
                  disableSelectionOnClick
                  onRowClick={(e) => {
                    setPhotoToShow(monitoringsPhotos.find(item => item.id === e.row.id)); 
                  }}

                  onSelectionModelChange={(newSelection) => {
                    console.log('newSelection / monitoringsRightTableToUnbind: ', newSelection);
                    dispatch(selectMonitoringsPhotos(newSelection.selectionModel));
                  }}
                  selectionModel={selectedMonitoringsPhotos}  
                  id={"data-grid-right"}
                />

            </Grid>
            }

            {photoToShow &&
            <Grid item>
              <MonitoringsImage
                  height={250}
                  width={333}  
                  item={photoToShow}
              /> 
            </Grid>}      

        </Grid>
    </div>
  );
}