import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    RETRIEVE_MP_DETAILS,
    GET_FILTERED_MP_DETAILS,
    GET_FILTERED_MP_DETAILS_SIMPLE,
    GET_FILTERED_MP_DETAILS_WITHOUT_BRAND,
    UPDATE_FILTERED_MP_DETAIL,
    REMOVE_BINDED_MP_DETAIL_FROM_FILTERED_MP_DETAILS_WITHOUT_BRAND,
    GET_ALL_CITIES_FROM_MP_DETAILS, 
    GET_FILTERED_CITIES_FROM_MP_DETAILS,
    GET_JOINT_CITIES_FROM_MP_DETAILS,
    GET_FILTERED_UNIT_TYPES_FROM_MP_DETAILS,
    GET_FILTERED_CLIENT_TYPES_GO_FROM_MP_DETAILS,
    GET_FILTERED_CLIENTS_GO_FROM_MP_DETAILS,
    GET_MP_DETAILS_FOR_REPORT,
    
  } from "../actions/types";

import store from '../store';

  
const initialState = {
    mpDetails: [],
    filteredMpDetails: [],
    mpDetailsWithoutBrand: [],
    allCities: [],    
    filteredCities: [],
    jointCities: [],
    filteredUnitTypes: [],
    filteredClientTypesGoFromMpDetails: [],
    filteredClientsGoFromMpDetails: [],
    mpDetailsForReport: [],    
};
  
function mpDetailsReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
  
      case RETRIEVE_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                mpDetails: payload
        }

      case GET_FILTERED_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredMpDetails: payload
        }

      case GET_FILTERED_MP_DETAILS_SIMPLE:
        if(!payload) return state;
        return {
                ...state,
                filteredMpDetailsSimple: payload
        }

      case GET_FILTERED_MP_DETAILS_WITHOUT_BRAND:
        if(!payload) return state;
        return {
                ...state,
                mpDetailsWithoutBrand: payload
        }
        
      case UPDATE_FILTERED_MP_DETAIL:   // update mprd_communication_id & mpc_name only!
        if(!payload) return state;

        const mpdIndex = state.filteredMpDetails.findIndex(item => item.mprd_id === payload.mprd_id);
        console.log('mpdIndex: ', mpdIndex);
        const newArray = [...state.filteredMpDetails];
        newArray[mpdIndex].mprd_communication_id = payload.mprd_communication_id;
        newArray[mpdIndex].mpc_name = payload.mpc_name;
        return {
                ...state,
                filteredMpDetails: newArray
        }

      case GET_ALL_CITIES_FROM_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                allCities: payload
        }

      case GET_FILTERED_CITIES_FROM_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredCities: payload
        }

      case GET_JOINT_CITIES_FROM_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                jointCities: payload
        }

      case GET_FILTERED_UNIT_TYPES_FROM_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredUnitTypes: payload
        }

      case GET_FILTERED_CLIENT_TYPES_GO_FROM_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredClientTypesGoFromMpDetails: payload
        }

      case GET_FILTERED_CLIENTS_GO_FROM_MP_DETAILS:
        if(!payload) return state;
        return {
                ...state,
                filteredClientsGoFromMpDetails: payload
        }

      case REMOVE_BINDED_MP_DETAIL_FROM_FILTERED_MP_DETAILS_WITHOUT_BRAND:
        if(!payload) return state;
        // const index = state.mpDetailsWithoutBrand.findIndex(mpDetails => mpDetails.mprd_id === payload);
        // console.log('index / mpDetails Reducer: ', index);
        // const newArr = [...state.mpDetailsWithoutBrand.slice(0, index), ...state.mpDetailsWithoutBrand.slice(index + 1)];
        return {
                ...state,
                mpDetailsWithoutBrand: state.mpDetailsWithoutBrand.filter(item => item.mprd_id !== payload)
        }
      
      case GET_MP_DETAILS_FOR_REPORT:
        if(!payload) return {
          ...state,
          mpDetailsForReport: state.filteredMpDetails,
        };

        let array;
        if(payload.selectedFlag == 'Yes') {
          array = payload.filteredMpDetails.filter(item => item.mprd_communication_id !==0)
        }
        else {
          array = payload.filteredMpDetails;
        }

        if(payload.duplicatesFlag == 'Yes') {
          const duplicates = payload.filteredMpDetails.filter(item => item.mprd_communication_id !== 0);
          array = duplicates.reduce(function(acc, item, index, duplicates){
            const newArray = [...duplicates.slice(0,index), ...duplicates.slice(index+1)].map(item => item.mpc_name); 
            if(newArray.includes(item.mpc_name)) {
              return acc.concat(item)
            }
            else {
              return acc
            }
          }, []);
        }

        return {
                ...state,
                mpDetailsForReport: array
        }
        
            
      default:
        return state;
  };
 }
  
export default mpDetailsReducer;