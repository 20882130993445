import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/web_orders`, { headers: authHeader() })
};

const getWoIdsByTimePeriodId = (wod_time_period_id) => {
    return axios.get(_baseApiUrl + `/web_orders/by_time_period_id/${wod_time_period_id.wod_time_period_id}`, { headers: authHeader() })
}

export default {
    getAll,
    getWoIdsByTimePeriodId,
};