import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

// надо getAll оставить за стандартным запросом на все, а для Brands написать filter по аналогии с mpDetails
const getAll = () => {
    return axios.get(_baseApiUrl + `/mpr_details/set_client`, { headers: authHeader() });
};

const get = (id) => {
    return axios.get(_baseApiUrl + `/mpr_details/${id}`, { headers: authHeader() })
}

const filter = (filter) => {
    return axios.post(_baseApiUrl + `/mpr_details/filter`, filter, { headers: authHeader() });
};

const mprDetailsForBrands = (filter) => {
    return axios.post(_baseApiUrl + `/mpr_details/mpr_details_for_brands`, filter, { headers: authHeader() });
};

const update = (id, data) => {
    return axios.patch(_baseApiUrl + `/mpr_details/${id}`, data, { headers: authHeader() });
};

const updateMany = (data, token) => {
    return axios.put(_baseApiUrl + `/mpr_details/set_client`, data, { headers: authHeader() }); 
};

const brands = () => {
    return axios.get(_baseApiUrl + `/mpr_details/get_brands`, { headers: authHeader() });
}

const brandsForBrands = (filter, unrelated) => {
    return axios.post(_baseApiUrl + `/mpr_details/brands_for_brands`, filter, { headers: authHeader() });
}

const getByMpdId = (mpd_id) => {
    return axios.get(_baseApiUrl + `/mpr_details/get_by_mpd_id/${mpd_id}`, { headers: authHeader() })
}

const forMonitoringsSimple = (data) => {
    return axios.post(_baseApiUrl + `/mpr_details/for_monitorings_simple`, data, { headers: authHeader() });
}

const forMonitorings = (data) => {
    return axios.post(_baseApiUrl + `/mpr_details/for_monitorings`, data, { headers: authHeader() });
}

export default {
    getAll,
    get,
    filter,
    forMonitoringsSimple,
    forMonitorings,
    mprDetailsForBrands,
    update,
    updateMany,
    brands,
    brandsForBrands,
    getByMpdId,
};