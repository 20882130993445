import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/cities`, { headers: authHeader() })
};

const getCitiesByReport = (mpr_id) => {
    return axios.get(_baseApiUrl + `/cities/by_report/${mpr_id.mpr_id}`, { headers: authHeader() })
}

export default {
    getAll,
    getCitiesByReport,
};