import axios from 'axios';
import {_baseApiUrl} from '../redux/constants';
import authHeader from './auth-header';

const getAll = () => {
    return axios.get(_baseApiUrl + `/unit_types`, { headers: authHeader() })
};


export default {
    getAll,
};