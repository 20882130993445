import {
    RESET_THE_STORE_TO_INITIAL_STATE,
    GET_WO_IDS_BY_TIME_PERIOD_ID,
  } from "../actions/types";
  
  const initialState = {
    wo_ids: [],
  };
  
  function webOrdersReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case RESET_THE_STORE_TO_INITIAL_STATE:
        return {
          ...initialState
        }
      
      case GET_WO_IDS_BY_TIME_PERIOD_ID:
        return {
          ...state,
          wo_ids: payload,
      }
  
      default:
        return state;
    }
  };
  
  export default webOrdersReducer;