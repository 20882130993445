import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MonitoringsMenu from '../monitoringsMenus/monitoringsMenu';
import GeoMenu from '../monitoringsMenus/geoMenu';

import { selectMonitoringsAutoTableAddresses, monitoringsPhotos, selectedMonitoringsPhotos, selectMonitoringsPhotos  } from '../../redux/actions/mprDetails';
import { selectClientsGo } from '../../redux/actions/clientsGo';
import { showAuto, showSide, showType} from '../../redux/actions/shared';
import { saveLog } from '../../redux/actions/mlogs';

import filesService from "../../services/filesService";
import mprDetailsPhotosService from "../../services/mprDetailsPhotosService";

import { to7digitals, takeUfId } from '../../utils/shared';
import {_baseApiUrl} from '../../redux/constants';
import { selectMprDetails, updateFilteredMprDetailsSimple, updateAutoTableData } from '../../redux/actions/mprDetails'; 


const useStyles = makeStyles((theme) => ({ 
    export: {
      color: 'black',
    },
    formcontrol: {
      paddingLeft: '14px;'
    },
    checkbox: {
      visibility: 'visible',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    dialogcontainer: {
      marginBottom: '10px',
    },
    info: {
        padding: '14px;'
    },
    success: {
      flexBasis: '75%',
      padding: '14px;',
      marginRight: '0px;',
      color: '#fff',
    },
    cancel: {
      flexBasis: '25%',
      padding: '14px;',
      color: '#fff',
    },
  }));

export default function MonitoringsActions() {
  const classes = useStyles();

  const {user_id, user_role, user_permissions} = useSelector(state => state.users);
  const {filteredMprDetails, selectedMprDetails, filteredMprDetailsSimple, monitoringsAutoTableAddresses, monitoringsPhotos, selectedMonitoringsPhotos, mprDetails} = useSelector(state => state.mprDetails);
  const {selectedClientsGo} = useSelector(state => state.clientsGo);
  const {mode, autoFlag, sideFlag, typeFlag, selectedIndex} = useSelector(state => state.shared);  

  const dispatch = useDispatch();

  
  const handleAuto = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showAuto(e.target.checked ? 'Yes' : 'No'));
    dispatch(selectMonitoringsAutoTableAddresses([]));
    dispatch(selectClientsGo([]));
  }

  const handleSide = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showSide(e.target.checked ? 'Yes' : 'No'));
  }

  const handleType = (e) => {
    console.log('e.target.checked: ', e.target.checked);
    dispatch(showType(e.target.checked ? 'Yes' : 'No'));
  }

  const bindOne = async (selectedClient, selectedMprDetail) => { // 1 client + 1 mprDetail
    const fileName = to7digitals(selectedMprDetail.mprdp_photo_assigned_uf_id) + "_" + to7digitals(selectedMprDetail.uf_rand) + "_900." + selectedMprDetail.uf_extension;

    const sourceProjectDirectory = to7digitals(selectedMprDetail.mprd_mpr_id);
    const targetProjectDirectory = to7digitals(selectedClient.mpr_id_simple);

    const photoUrl = "https://monitoring.graceoutdoor.ru/" + targetProjectDirectory + "/" + fileName;

    if(!fileName || !sourceProjectDirectory || !targetProjectDirectory){
      alert('Ошибка с параметрами копирования');
      console.log('fileName / M ACTIONS: ', fileName);
      console.log('папка откуда / M ACTIONS: ', sourceProjectDirectory);
      console.log('папка куда / M ACTIONS: ', targetProjectDirectory);
      console.log('внешняя ссылка / M ACTIONS: ', photoUrl);

      return;
    }
    
    //const res = await mprDetailsService.getByMpdId(selectedClient.id); 
    //const mprDetailsIdSimple = res.data[0].mprd_id;
    const mprDetailsIdSimple = selectedClient.mprd_id_simple;
    console.log('mprDetailsIdSimple / M ACTIONS: ', mprDetailsIdSimple);

    const res2 = await mprDetailsPhotosService.get(selectedMprDetail.mprdp_id);
    const mprdPhotos = res2.data;
    delete mprdPhotos.mprdp_id;
  
    const obj = {
      file_name: fileName, 
      source_dir: sourceProjectDirectory,
      target_dir: targetProjectDirectory,
    }
    
    const newMprdPhotos = Object.assign({}, mprdPhotos, {mprdp_mprd_id: mprDetailsIdSimple, mprdp_deleted: 0, mprdp_photo_checked: 0});
    
    console.log('obj / M ACTIONS: ', obj);
    console.log('newMprdPhotos / M ACTIONS: ', newMprdPhotos);
    console.log('внешняя ссылка / M ACTIONS: ', photoUrl);

    const res3 = await filesService.copyFile(obj);
    console.log('результат копирования фото: / M ACTIONS', res3.data);

    const res4 = await mprDetailsPhotosService.create(newMprdPhotos);
    console.log('результат добавления записи в mprdp: / M ACTIONS', res4.data); 
    
    console.log('данные для update MPR DETAILS SIMPLE:', mprDetailsIdSimple, [].concat(photoUrl), res4.data.mprdp_photo_assigned_uf_id, 0 )
    dispatch(updateFilteredMprDetailsSimple(mprDetailsIdSimple, [].concat(photoUrl), res4.data.mprdp_photo_assigned_uf_id, 0  ));
    if(autoFlag === 'Yes') dispatch(updateAutoTableData(mprDetailsIdSimple, [].concat(photoUrl), res4.data.mprdp_photo_assigned_uf_id, 0  ));

    const mLog = {
      ml_mu_id: user_id,
      ml_mt_id: 5,
      ml_ma_id: 12 , 
      ml_qty: 1,
      ml_comments: JSON.stringify({
        fileName: fileName,
        source_dir: sourceProjectDirectory,
        target_dir: targetProjectDirectory,
        photoUrl: photoUrl, 
        newMprdPhotos: res4.data.mprdp_id, 
      }),
    }

    dispatch(saveLog(mLog));
  }

  const bindMany = async (selectedClient, selectedMprDetails) => {
    let newPhotosArray = [];
    for (let i = 0; i<selectedMprDetails.length;i++) {
      await bindOne(selectedClient, selectedMprDetails[i]);  
      newPhotosArray.push("https://monitoring.graceoutdoor.ru/" + to7digitals(selectedClient.mpr_id_simple) + "/" + to7digitals(selectedMprDetails[i].mprdp_photo_assigned_uf_id) + "_" + to7digitals(selectedMprDetails[i].uf_rand) + "_900." + selectedMprDetails[i].uf_extension)
    }
    return newPhotosArray
  }

  const handleBind = async (e) => { 

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'monitorings-BIND_MASS_MONITORING_PHOTO')) {
      alert('В доступе отказано. Обратитесь к администратору'); 
      return;   
    }

    console.log('====== ПРИВЯЗКА ФОТО МАСС МОНИТОРИНГА К ОБЫЧНОМУ ОТЧЕТУ ======'); 

    if(autoFlag === 'No') {
      console.log('------ ДВЕ ТАБЛИЦЫ ------');

      if(selectedMprDetails.length === 0 || selectedClientsGo.length === 0) {alert('Выберите записи в обеих таблицах'); return;}
        
      const newPhotos = await bindMany(selectedClientsGo[0], selectedMprDetails);
     
      dispatch(selectClientsGo([]));
      dispatch(selectMprDetails([]));
    }
    else {
      console.log('------ ОДНА АВТО ТАБЛИЦА ------');

      if(monitoringsAutoTableAddresses.length === 0) {
        alert('Выберите хотя бы одну запись'); 
        return;
      }

      if(monitoringsAutoTableAddresses.filter(item => item.address === 'НЕТ ВАРИАНТОВ').length > 0) {
        alert('Снимите галочку для строк НЕТ ВАРИАНТОВ'); 
        return;
      }

      console.log('monitoringsAutoTableAddresses: ', monitoringsAutoTableAddresses);

      for (let i = 0; i<monitoringsAutoTableAddresses.length;i++) {
        await bindOne(monitoringsAutoTableAddresses[i], monitoringsAutoTableAddresses[i]);    
      }
      
      dispatch(selectMonitoringsAutoTableAddresses([]));
    }    
  }

  const unBindOne = async (selectedClient, ufId) => {  
    
    const mpr_detail_photos = {
      mprdp_deleted: 1,
    };

    //const res = await mprDetailsPhotosService.getOneByUfId(ufId);
    // const mprdpId = res.data[0].mprdp_id;
    const mprdpId = mprDetails.find(item => item.mpd_id === selectedClient.id && item.mprdp_photo_assigned_uf_id === ufId).mprdp_id;
    console.log('mprdpId / M ACTIONS, to Unbind: ', mprdpId);
    
    const res = await mprDetailsPhotosService.get(mprdpId);
    console.log('mprdp, to Unbind: ', res.data);

    if(res.data.mprdp_photo_checked === 1 || res.data.mprdp_photo_checked === 2) {
      alert("Отвязка невозможна, т.к. сторона проверена на WO");
      return;
    }

    const result = await mprDetailsPhotosService.update(mprdpId, mpr_detail_photos);
    console.log('обновленная mprDetailsPhotos: ', result.data);

    // const mprDetailsIdSimple = selectedClient.mprd_id_simple;
    // console.log('mprDetailsIdSimple / M ACTIONS, to Unbind: ', mprDetailsIdSimple);

    dispatch(updateFilteredMprDetailsSimple(
      selectedClient.mprd_id_simple, 
      [], 
      ufId, 
      1,
      ufId 
    ));

    dispatch(selectClientsGo([]));

    const mLog1 = {
      ml_mu_id: user_id,
      ml_mt_id: 5,
      ml_ma_id: 12 , 
      ml_qty: 1,
      ml_comments: JSON.stringify({
        mprdp_id: mprdpId,
        mprdp_deleted: 1,
        mprdp_photo_assigned_uf_id: ufId,
      }),
    }
    dispatch(saveLog(mLog1));
  }

  const handleUnbind = async () => {

    if(user_role === 'User' && !user_permissions.actions.find(action => action === 'monitorings-BIND_MASS_MONITORING_PHOTO')) {
      alert('В доступе отказано. Обратитесь к администратору'); 
      return;   
    }

    console.log('====== ОТВЯЗКА ФОТО МАСС МОНИТОРИНГА от ОБЫЧНОГО ОТЧЕТА ======');

    if(selectedClientsGo.length === 0 || selectedMonitoringsPhotos.length === 0) {
      alert('Выберите хотя бы одну запись и одну фотографию'); 
      return;
    }

    console.log('selectedClientsGo / ОТВЯЗКА: ', selectedClientsGo);
    console.log('monitoringsPhotos / ОТВЯЗКА: ', monitoringsPhotos);
    console.log('selectedMonitoringsPhotos / ОТВЯЗКА: ', selectedMonitoringsPhotos);

    const ufIds = monitoringsPhotos.filter(item => selectedMonitoringsPhotos.includes(item.id)).map(jtem => takeUfId(jtem.photo));
    console.log('ufIds / ОТВЯЗКА: ', ufIds); 

    for (let i = 0; i<ufIds.length;i++) {
      await unBindOne(selectedClientsGo[0], ufIds[i]);
    }

    dispatch(selectClientsGo([]));
    dispatch(selectMonitoringsPhotos([]));
  }  

  return (
    <div>
        {filteredMprDetails.length>0 && 
        <Grid container spacing={2} alignItems={'center'} className={classes.dialogcontainer}>             
           
          
            {mode !== 'F2' && mode !== 'F1' &&
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={autoFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleAuto}
                            />}
                    label="Авто"
                    labelPlacement="end"
                />
            </Grid>}


            <Grid item>    
                <MonitoringsMenu
                  options = {['Непривязанные', 'Привязанные', 'Все']}
                />
            </Grid>

            {autoFlag === 'No' && selectedIndex === 1 &&
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={sideFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleSide} 
                            />}
                    label="Ст."
                    labelPlacement="end"
                />
            </Grid>
            }

            {autoFlag === 'No' && selectedIndex === 1 &&
            <Grid item>
                <FormControlLabel
                    className={classes.formcontrol}
                    value="end"
                    control={<Checkbox 
                            checked={typeFlag == 'Yes' ? true : false}
                            color="secondary"
                            className={classes.checkbox}
                            onClick={handleType} 
                            />}
                    label="Тип"
                    labelPlacement="end"
                />
            </Grid>
            }

            {autoFlag === 'No' && selectedIndex === 1 &&
            <Grid item>    
                <GeoMenu/>
            </Grid>
            }


            {selectedIndex === 1 && filteredMprDetailsSimple.length > 0 &&
            <Grid item>
                <Button
                    variant="contained"
                    size="large"
                    color="secondary" 
                    className={classes.success}
                    onClick={handleBind}
                >
                    Привязать
                </Button>
            </Grid>}

            {selectedIndex === 2 && filteredMprDetailsSimple.length > 0 && mode === 'F2' && selectedMonitoringsPhotos && selectedMonitoringsPhotos.length>0 &&
            <Grid item>
                <Button
                    variant="contained"
                    size="large"
                    color="primary" 
                    className={classes.cancel}
                    onClick={handleUnbind}
                >
                    Отвязать
                </Button>
            </Grid>}

        </Grid>}
      
    </div>
  );
}