import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux"; 
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'; 
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { lightBlue } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import ComboBox from '../comboBox/comboBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckboxesTags from '../multiSelect/multiSelect';
import MultiSelectWithAllOptionPlain from '../multiSelectWithAllOptionPlain/multiSelectWithAllOptionPlain';
 
import { getBrands, getBrandsForBrands } from '../../redux/actions/mprDetails';
import {getByType} from '../../services/clientsGoService';
import { retrieveClientsGoByType, getClientsGoAndBrands } from '../../redux/actions/clientsGo';
import { getMonthsRelatedToTheClientGo } from '../../redux/actions/mprDetails'; 

import {_baseApiUrl} from '../../redux/constants';
import { getFilteredMpDetails, getFilteredCitiesFromMpDetails, getFilteredMpDetailsWithoutBrand} from '../../redux/actions/mpDetails';
import { getFilteredMprDetails } from '../../redux/actions/mprDetails';
import { getFilteredMpReports } from '../../redux/actions/mpReports';
import { setTheFilter } from '../../redux/actions/shared';

import { cityNameToId } from '../../utils/shared'; 


const useStyles = makeStyles((theme) => ({     
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minWidth: '400px;',
        width: '100%',
    },
    success: {
        flexBasis: '75%',
        padding: '14px;',
        marginRight: '0px;',
        color: '#fff',
        // marginBottom: '10px;',
    },
    cancel: {
        flexBasis: '25%',
        padding: '14px;',
        color: '#fff',
        // backgroundColor: theme.palette.secondary.main,
    },
    additional: {
        flexBasis: '75%',
        padding: '14px;',
        backgroundColor: lightBlue[700],
        color: '#fff',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
  }));  

export default function BrandsDialog(props) {
  const classes = useStyles();

  const {monitoringProjects} = props;

  const {unrelated} = useSelector(state => state.mprDetails);
  const { filteredMpReports } = useSelector(state => state.mpReports);
  const { allCities, filteredCities } = useSelector(state => state.mpDetails);
  const { user_role, user_permissions} = useSelector(state => state.users);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  
  const [mpd_mp_id, setMpdMpId] = useState([]);
  const [mpr_id, setMprId] = useState([]);
  const [mpd_city, setMpdCity] = useState([]);

  const getFilter = function(dtoObj, property, action){
    return function(newValue){
      dtoObj[property] = newValue;
      console.log('obj', dtoObj);
      dispatch(action(dtoObj));
    }
  };

  const loadBrands = () => {

    const filter = {
      mpd_mp_id: mpd_mp_id,
      mpr_id: mpr_id,
      mpd_city: mpd_city,
    };
    console.log('filter: ', filter);
    //dispatch(getBrands(filter, unrelated)); 
    //dispatch(getFilteredMprDetails(filter, unrelated)); 
    dispatch(setTheFilter(filter));
    
    handleClose(); 
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log('mpd_mp_id: ', mpd_mp_id);
  // console.log('mpr_id: ', mpr_id);
  // console.log('mpd_city: ', mpd_city);
  console.log('allCities ', allCities);
  console.log('filteredCities: ', filteredCities);
  console.log('user_permissions.cities: ', user_permissions.cities);


  return (
    <div>
        <Button
            variant="contained"
            size="large"
            color="secondary" 
            className={classes.success}
            onClick={handleClickOpen}
        >
            Фильтр
        </Button>
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Фильтр</DialogTitle>
        
        <DialogContent>
            <form className={classes.container}>
              <Grid container spacing={1} display="flex" direction="column"  wrap="nowrap" alignItems="flex-start"> 

              <Grid item>
                <MultiSelectWithAllOptionPlain
                  style={{
                    width: 550,
                  }}
                  label={'Проекты мониторинга'} 
                  items={user_role === 'Admin' ? monitoringProjects : monitoringProjects.filter(item => user_permissions.projects.includes(item))}
                  value={mpd_mp_id}
                  selected={mpd_mp_id}
                  onChange={setMpdMpId}
                  fn={getFilter(
                    {
                      mpd_mp_id: mpd_mp_id,
                    },
                    'mpd_mp_id',
                    getFilteredMpReports
                  )} 
                />
              </Grid> 

              {mpd_mp_id.length>0 && filteredMpReports.length>0 &&
              <Grid item>
                <MultiSelectWithAllOptionPlain
                  style={{
                    width: 550,
                  }}
                  label={'Отчеты мониторинга'}
                  items={user_role === 'Admin' ? filteredMpReports : filteredMpReports.filter(item => user_permissions.reports.includes(item))}
                  value={mpr_id}
                  selected={mpr_id}
                  onChange={setMprId}
                  fn={getFilter(
                    {
                      mpd_mp_id: mpd_mp_id, 
                      mpr_id: mpr_id,
                    },
                    'mpr_id',
                    getFilteredCitiesFromMpDetails
                  )} 
                />
              </Grid>}

              {mpd_mp_id.length>0 && mpr_id.length>0 && filteredCities.length>0 &&
              <Grid item>
                <MultiSelectWithAllOptionPlain
                  style={{
                    width: 550,
                  }}
                  label={'Города'}
                  items={user_role === 'Admin' ? filteredCities : filteredCities.filter(item => user_permissions.cities.includes(cityNameToId(item, allCities)))}
                  value={mpd_city}
                  selected={mpd_city}
                  onChange={setMpdCity}/>
              </Grid> }  
               
              </Grid>
            </form>
        </DialogContent> 
          
        
        
        <DialogActions>
          <Button 
            onClick={handleClose} 
            color="primary"
          >
            Отмена
          </Button>

          {mpd_mp_id.length>0 && mpr_id.length>0 && mpd_city.length>0 &&
          <Button 
            color="secondary"
            onClick={loadBrands}
          >
            Загрузить бренды
          </Button>}

        </DialogActions>

      </Dialog>
    </div>
  );
}