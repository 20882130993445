import React, {useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ImageGallery from 'react-image-gallery';
import './monitoringsImageGallery.scss';
import { gridClasses } from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
    imagelistitem: {
      marginRight: '10px;',
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      marginBottom: '20px;',
    },
    dialog: {
      overflowY: 'unset',
    },
    button: {
      padding: 15,
    },
    link: {
      display: 'block',
      width: 333, 
      height: 250,
      padding: 15,
    }, 
  }));

export default function MonitoringsImageGallery (props) {
    const classes = useStyles();
    
    const { item, height, width } = props;
    console.log('item / MonitoringsImageGallery: ', item);

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);   // open fullWidth image
  
    const handleDoubleClick = (e) => {
      setOpen((prev) => !prev);
    };
        
    const toImages = (pathOriginal, pathThumbnail) => {
        return {
            original: pathOriginal,
            thumbnail: pathThumbnail
        }
    };
    
    let images = item && item.photos && item.photos.length>0
        ? [...new Set(item.photos)].map(jtem => {
            return toImages(jtem, null)
            })
        : [];     
    console.log('images / monitoringsImageGallery: ', images);
    
    if(images.length>20) images.length = 20;
  
  return item && item.photos && item.photos.length>0 
    ? (
       
            <ImageGallery 
                items={images}
                //fullscreen
                showThumbnails={false}
                showPlayButton={false}
                showIndex={true}
                // showFullscreenButton={true}
            />
    
    )
    : item.passport.substr(-3) === 'jpg' && item.passport.substr(0,5) === 'https'
      ? (
          <div
              className={classes.imagelistitem}
              onDoubleClick={handleDoubleClick}
          >
            <Dialog
                classes={{paper: classes.dialog}}
                aria-labelledby="simple-dialog-title" 
                open={open}
            >
              <img src={item.passport}/>
            </Dialog>
              
              <img
                src={item.passport}
                alt={'Фото мониторинга'}
                height={height ? height : 'auto'}
                width={width ? width : 'auto'}
              />

          </div>
        )   
      : (
          <a
              target="_blank" 
              href={item && item.passport ? item.passport : 'https://via.placeholder.com/333x250'}
              rel="noopener noreferrer"
              className={classes.link}
          >  
              <Typography variant="h5" align="center" className={classes.button}>
                  Смотреть паспорт
                  <Typography variant="subtitle2" align="center"> 
                      {item && item.passport ? item.passport : 'https://via.placeholder.com/333x250'}
                  </Typography>
              </Typography>
          </a>       
      )
      
};