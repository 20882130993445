export const CREATE_CLIENTS_GO = "CREATE_CLIENTS_GO"; 
export const RETRIEVE_CLIENTS_GO = "RETRIEVE_CLIENTS_GO";
export const RETRIEVE_CLIENTS_GO_BY_TYPE = "RETRIEVE_CLIENTS_GO_BY_TYPE";
export const GET_FILTERED_CLIENTS_GO = "GET_FILTERED_CLIENTS_GO";
export const UPDATE_CLIENTS_GO = "UPDATE_CLIENTS_GO";
export const DELETE_CLIENTS_GO = "DELETE_CLIENTS_GO";
export const SELECT_CLIENTS_GO = "SELECT_CLIENTS_GO";
export const FILTER_CLIENTS_GO = "FILTER_CLIENTS_GO";
export const GET_BRANDS_FROM_MPR_DETAILS = "GET_BRANDS_FROM_MPR_DETAILS";
export const GET_CLIENTS_GO_AND_BRANDS = "GET_CLIENTS_GO_AND_BRANDS";
export const ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS = "ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS";
export const CLIENTS_GO_AND_BRANDS = "ADD_BRAND_TO_CLIENTS_GO_AND_BRANDS";
export const SET_CLIENT_GO_FILTER = "SET_CLIENT_GO_FILTER"; // Monitorings / MonitoringsActions


export const SELECT_CHOSEN = "SELECT_CHOSEN";

export const RETRIEVE_MPR_DETAILS = "RETRIEVE_MPR_DETAILS";
export const GET_FILTERED_MPR_DETAILS = "GET_FILTERED_MPR_DETAILS";
export const GET_FILTERED_MPR_DETAILS_SIMPLE = "GET_FILTERED_MPR_DETAILS_SIMPLE";
export const GET_ONE_MPR_DETAIL_BY_ID = "GET_ONE_MPR_DETAIL_BY_ID";
// export const GET_MP_COMMUNICATION_FROM_MPR_DETAIL = "GET_MP_COMMUNICATION_FROM_MPR_DETAIL";
export const UPDATE_ONE_MPR_DETAIL = "UPDATE_ONE_MPR_DETAIL";
export const UPDATE_MANY_FILTERED_MPR_DETAILS = "UPDATE_MANY_FILTERED_MPR_DETAILS";
export const SELECT_MPR_DETAILS = "SELECT_MPR_DETAILS"; // Monitorings/RightTable
export const GET_MPR_DETAILS_FOR_MONITORINGS = "GET_MPR_DETAILS_FOR_MONITORINGS"; // Monitorings 
export const GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE = "GET_MPR_DETAILS_FOR_MONITORINGS_SIMPLE"; // Monitorings

export const SELECT_UNRELATED = "SELECT_UNRELATED";

export const BIND_PHOTO_TO_THE_CLIENTS_GO = "BIND_PHOTO_TO_THE_CLIENTS_GO";
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_FOR_BRANDS = "GET_BRANDS_FOR_BRANDS";
export const GET_BRANDS_RELATED = "GET_BRANDS_RELATED";
export const SELECT_BRANDS = "SELECT_BRANDS";
export const BIND_BRANDS_TO_THE_CLIENTS_GO = "BIND_BRANDS_TO_THE_CLIENTS_GO";          // it's work without redux: need to refactoring
export const UNBIND_BRANDS_FROM_THE_CLIENTS_GO = "UNBIND_BRANDS_FROM_THE_CLIENTS_GO";  // it's work without redux: need to refactoring
export const DELETE_THE_CLIENT_GO = "DELETE_THE_CLIENT_GO";  // it's work without redux: need to refactoring
export const EXPORT_TO_EXCEL = "EXPORT_TO_EXCEL";  // it's work without redux: need to refactoring

export const RETRIEVE_CLIENTS_TYPES_GO = "RETRIEVE_CLIENTS_TYPES_GO";

export const RETRIEVE_MP_DETAILS = "RETRIEVE_MP_DETAILS";
export const GET_FILTERED_MP_DETAILS = "GET_FILTERED_MP_DETAILS";                   // mass monitoring 
export const GET_FILTERED_MP_DETAILS_SIMPLE = "GET_FILTERED_MP_DETAILS_SIMPLE";     // simple monitoring
export const GET_FILTERED_MP_DETAILS_WITHOUT_BRAND = "GET_FILTERED_MP_DETAILS_WITHOUT_BRAND";
export const UPDATE_FILTERED_MP_DETAIL = "UPDATE_FILTERED_MP_DETAIL";
export const REMOVE_BINDED_MP_DETAIL_FROM_FILTERED_MP_DETAILS_WITHOUT_BRAND = "REMOVE_BINDED_MP_DETAIL_FROM_FILTERED_MP_DETAILS_WITHOUT_BRAND";
export const GET_MP_DETAILS_FOR_REPORT = "GET_MP_DETAILS_FOR_REPORT";

export const GET_MONTHS_RELATED_TO_THE_CLIENT = "GET_MONTHS_RELATED_TO_THE_CLIENT";  // used in MpdDetails actions
export const GET_MONTHS_FROM_MP_REPORTS = "GET_MONTHS_FROM_MP_REPORTS"; // used in MpReports actions

export const RETRIEVE_MONITORING_PROJECTS = "RETRIEVE_MONITORING_PROJECTS";

export const GET_FILTERED_MP_REPORTS = "GET_FILTERED_MP_REPORTS";
export const GET_FILTERED_MONITORING_PROJECTS = "GET_FILTERED_MONITORING_PROJECTS";
export const GET_ALL_CITIES_FROM_MP_DETAILS = "GET_ALL_CITIES_FROM_MP_DETAILS";
export const GET_FILTERED_CITIES_FROM_MP_DETAILS = "GET_FILTERED_CITIES_FROM_MP_DETAILS";
export const GET_JOINT_CITIES_FROM_MP_DETAILS = "GET_JOINT_CITIES_FROM_MP_DETAILS";
export const GET_FILTERED_UNIT_TYPES_FROM_MP_DETAILS = "GET_FILTERED_UNIT_TYPES_FROM_MP_DETAILS";
export const GET_FILTERED_CLIENT_TYPES_GO_FROM_MP_DETAILS = "GET_FILTERED_CLIENT_TYPES_GO_FROM_MP_DETAILS";
export const GET_FILTERED_CLIENTS_GO_FROM_MP_DETAILS = "GET_FILTERED_CLIENTS_GO_FROM_MP_DETAILS";
export const GET_MP_DETAILS_FOR_MONITORINGS = "GET_MP_DETAILS_FOR_MONITORINGS";

export const RETRIEVE_MP_COMMUNICATIONS = "RETRIEVE_MP_COMMUNICATIONS";
export const GET_CURRENT_MP_COMMUNICATION = "GET_CURRENT_MP_COMMUNICATION";
export const CREATE_MP_COMMUNICATIONS = "CREATE_MP_COMMUNICATIONS";
export const BIND_MP_COMMUNICATION_TO_THE_PHOTO = "BIND_MP_COMMUNICATION_TO_THE_PHOTO"; // it's work without redux: need to refactoring


export const RESET_THE_STORE_TO_INITIAL_STATE = "RESET_THE_STORE_TO_INITIAL_STATE";
export const SET_THE_FILTER = "SET_THE_FILTER";
export const SET_THE_MODE = "SET_THE_MODE";


export const SHOW_SELECTED = "SHOW_SELECTED";      // Makets
export const SHOW_UNSELECTED = "SHOW_UNSELECTED";  // Makets
export const SHOW_DUPLICATES = "SHOW_DUPLICATES";  // Makets
export const SHOW_LIST = "SHOW_LIST";              // Makets

export const SHOW_AUTO = "SHOW_AUTO";             // Monitorings
export const SHOW_SIDE = "SHOW_SIDE";             // Monitorings
export const SHOW_TYPE = "SHOW_TYPE";             // Monitorings
export const SHOW_GEO = "SHOW_GEO";             // Monitorings
export const SEARCH_STRING = "SEARCH_STRING";     // Monitorings

export const SET_USER = "SET_USER";
export const CREATE_USER = "CREATE_USER";

export const RETRIEVE_CITIES = "RETRIEVE_CITIES";
export const RETRIEVE_UNIT_TYPES = "RETRIEVE_UNIT_TYPES"; 
export const RETRIEVE_MTABS = "RETRIEVE_MTABS";
export const RETRIEVE_MACTIONS = "RETRIEVE_MACTIONS";


export const SAVE_LOG = "SAVE_LOG";
export const IS_LOADING = "IS_LOADING";
export const SET_AUTO_TABLE_DATA = "SET_AUTO_TABLE_DATA";

export const GET_CITIES_BY_REPORT = "GET_CITIES_BY_REPORT"; // Monitorings
export const GET_TIME_PERIOD_ID_BY_REPORT = "GET_TIME_PERIOD_ID_BY_REPORT"; // Monitorings
export const GET_WO_IDS_BY_TIME_PERIOD_ID = "GET_WO_IDS_BY_TIME_PERIOD_ID"; // Monitorings
export const RETRIEVE_ACTUAL_REPORTS = "RETRIEVE_ACTUAL_REPORTS"; // Monitorings, simple reports only, e.g. monitoring_projects.mp_espar_mm != 1
export const GET_REPORTS_BY_WO_IDS = "GET_REPORTS_BY_WO_IDS"; // Monitorings, mass reports only  => store mpReports filteredMpReports
export const UPDATE_FILTERED_MPR_DETAILS_SIMPLE = "UPDATE_FILTERED_MPR_DETAILS_SIMPLE"; // Monitorings , Actions
export const UPDATE_AUTO_TABLE_DATA = "UPDATE_AUTO_TABLE_DATA"; // Monitorings , Actions
export const SHOW_MONITORINGS_PHOTOS = "SHOW_MONITORINGS_PHOTOS"; // Monitorings , Actions
export const SELECT_MONITORINGS_PHOTOS = "SELECT_MONITORINGS_PHOTOS"; // Monitorings , Actions
export const SELECT_MONITORINGS_AUTO_TABLE_ADDRESSES = "SELECT_MONITORINGS_AUTO_TABLE_ADDRESSES"; // Monitorings
export const UPDATE_MPR_DETAIL_PHOTOS_SIMPLE = "UPDATE_MPR_DETAIL_PHOTOS_SIMPLE"; // Monitorings , Actions