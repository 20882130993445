import { combineReducers } from "redux";
import cities from './cities';
import clientsTypesGo from './clientsTypesGo';
import clientsGo from "./clientsGo";
import mactions from './mactions';
import mlogs from './mlogs';
import mpCommunications from './mpCommunications';
import mpDetails from "./mpDetails";
import mprDetails from './mprDetails';
import mpReports from './mpReports';
import monitoringProjects from './monitoringProjects';
import mtabs from './mtabs';
import shared from './shared';
import unitTypes from "./unitTypes";
import users from './users';
import webOrders from './webOrders';


export default combineReducers({
  cities,
  clientsTypesGo,
  clientsGo,
  mactions,
  mlogs,
  mpCommunications,
  mpDetails,
  mprDetails,
  mpReports,
  monitoringProjects,
  mtabs,
  shared,
  unitTypes,
  users,
  webOrders,
});
