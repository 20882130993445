import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, withTheme } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import MonitoringsImage from '../monitoringsImage/monitoringsImage';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    maxWidth: '50vw', 
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    overflowX: 'hidden',
    margin: '0px;',
    flexWrap: 'nowrap',

    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  imagelistitem: {
    marginRight: '10px;',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginBottom: '20px;',
  },
  dialog: {
    overflowY: 'unset',
  },
  title: {
    color: theme.palette.primary.light,
    marginLeft: '0px;',
  },
  showImage: {
    height: '300px;',
    width: 'auto',
    opacity: '1',
  },
  button: {
    padding: 15,
  },
  link: {
    display: 'block',
    width: 333, 
    height: 250,
    padding: 15,
    marginRight: 15,
    overflow: 'hidden',
  }, 
}));

const _transformMpDetailSimple = (mpDetailSimple) => {
  if(mpDetailSimple && mpDetailSimple.photos.length>0) {
    return mpDetailSimple.photos.map(item => {
      return {
          photo: item, 
          passport: mpDetailSimple.passport,
      }
    })
  }
  else {
    return      
  }
};

export default function MonitoringsPhotos(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {filteredMpDetails} = useSelector(state => state.mpDetails);
  const {selectedIndex, duplicatesFlag, filter} = useSelector(state => state.shared);

  const {item} = props;

  const arr = item && item.photos.length>0 
    ? _transformMpDetailSimple(item)
    : [];

  const [open, setOpen] = useState(false);   // open fullWidth image

  const handleDoubleClick = (e) => {
    setOpen((prev) => !prev);
  };

  // const handlePopoverOpen = (e) => {
  //   setAnchorEl(e.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  // const openPopover = Boolean(anchorEl);
  // const id = openPopover ? 'simple-popover' : undefined;
  


  // if(filteredMpDetails.length !== filteredMpDetails.map(item => item.mprd_id).length) alert("Есть дубли по mprd_id");

  // let array;

  // if(selectedIndex === 2) {
  //   array = filteredMpDetails.filter(item => item.mprd_communication_id !== 0);
  // }
  // else if (selectedIndex === 3) {
  //   array = filteredMpDetails.filter(item => item.mprd_communication_id === 0);
  // }
  // else {
  //   array = filteredMpDetails;
  // }

  // if(duplicatesFlag == 'Yes') {
  //   const duplicates = filteredMpDetails.filter(item => item.mprd_communication_id !== 0);
  //   array = duplicates.reduce(function(acc, item, index, duplicates){
  //     const newArray = [...duplicates.slice(0,index), ...duplicates.slice(index+1)].map(item => item.mpc_name); 
  //     if(newArray.includes(item.mpc_name)) {
  //       return acc.concat(item)
  //     }
  //     else {
  //       return acc
  //     }
  //   }, []);
  // }  

  // console.log('filteredMpDetails: ', filteredMpDetails); 
  console.log('item / MONITORINGS PHOTOS: ', item);
  console.log('arr / MONITORINGS PHOTOS: ', arr);


  return arr && arr.length>0  ? (
          <div className={classes.root}>
              <GridList className={classes.imageList} >               
                  {arr.map((el, index) => (
                  
                  <MonitoringsImage 
                      item={el}
                      key={index}
                      height={250} 
                      width={333} 
                  />
                
                ))}
                
              </GridList>
            
          </div>
       )
       : (
          // <Button style={{width: 333, height: 250}}>
            <a
              target="_blank" 
              href={item && item.passport ? item.passport : 'https://via.placeholder.com/333x250'}
              rel="noopener noreferrer"
              className={classes.link}
            >  
              <Typography variant="h5" align="center" className={classes.button}>
                  Смотреть паспорт
                  <Typography variant="subtitle2" align="center">
                    {item && item.passport ? item.passport : 'https://via.placeholder.com/333x250'}
                  </Typography>
                </Typography>
            </a> 
          // </Button>
          )
  }