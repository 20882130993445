import {
    RETRIEVE_UNIT_TYPES,
  } from "./types";
  
  import unitTypesService from "../../services/unitTypesService";
  import store from '../store';
  
  
  function _transformUnitTypes(item){
    return Object.assign({}, {id: item.utype_id, united: item.utype_name });    
  }  
  
  export const retrieveUnitTypes = () => async (dispatch) => {
    try {
      const res = await unitTypesService.getAll();
  
      dispatch({
        type: RETRIEVE_UNIT_TYPES,
        //payload: res.data.map(item => _transformUnitTypes(item)),
        payload: res.data.map(item => item.utype_name).sort(),
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  
  